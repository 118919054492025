import React, { useMemo } from 'react'
import CompanyTable from './CompanyTable'
import UserTable from './UserTable'
import TeamTable from './TeamTable'
import TabbedPage from '../_library/TabbedPage'


const DataPage = () => {
  
  const tabs = useMemo(() => [
    {
      key: 'list',
      label: 'Clients',
      route: 'list',
      content: <CompanyTable />,
    },
    {
      key: 'users',
      label: 'Users',
      route: 'users',
      content: <UserTable />,
    },
    {
      key: 'teams',
      label: 'Teams',
      route: 'teams',
      content: <TeamTable />,
    },
  ], [])
  
  return <TabbedPage tabs={tabs} />
}

export default DataPage
