import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { Grid, CircularProgress, Button } from '@mui/material'
import NewCompanyButton from './NewCompanyButton'
import MUIDataTable from 'mui-datatables'
import CompanyDetailsDialog from './CompanyDetailsDialog'
import DeleteButton from '../_library/DeleteButton'
import { format } from 'date-fns'
import { Edit } from '@mui/icons-material'
import { deleteDocAction } from '../../store/actions/firestoreActions'
import { COMPANY_COLLECTION } from '../../_constants/globals'
import { withStyles } from '@mui/styles'


const styles = theme => ({
  root: {
    margin: '20px auto 100px',
    maxWidth: 1200,
  },
  circularProgressContainer: {
    marginTop: 30,
  },
  tableRow: {
    borderBottom: `1px solid ${theme.palette.text.primary}`,
  },
  link: {
    color: 'rgba(0, 0, 0, .87)',
    padding: theme.spacing(1),
    minWidth: 40,
    borderRadius: 8,
    textDecoration: 'none',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
})

const CompanyTable = ({ classes, companies, deleteDocAction, users }) => {

  const [editCompany, setEditCompany] = useState(null)

  const data = useMemo(() =>
    companies?.map(c =>
      [c.name, c._createdAtTime, c],
    ), [companies, users])

  return !companies
    ? <Grid container item xs={12} justifyContent='center' className={classes.circularProgressContainer}>
      <CircularProgress size={20} />
    </Grid>
    : <>
      <MUIDataTable
        className={classes.root}
        title='Comptes clients'
        data={data}
        columns={[
          { name: 'Raison sociale' },
          { name: 'Création', options: { customBodyRender: date => date ? format(date.toDate(), 'dd-MM-yyyy') : 'No date' } },
          { name: '',
            options: {
              customBodyRender: company => // eslint-disable-line react/display-name
                <>
                  <Button onClick={() => setEditCompany(company)} style={{ minWidth: 40, display: 'inline-block' }}>
                    <Edit />
                  </Button>
                  <DeleteButton deleteAction={() => deleteDocAction(COMPANY_COLLECTION, company.id)} />
                </>,
            },
          },
        ]}
        options={{
          filterType: 'textField',
          responsive: 'simple',
          pagination: false,
          customToolbar: () => <NewCompanyButton />, // eslint-disable-line
          selectableRows: 'none',
        }}
      />
      {editCompany && <CompanyDetailsDialog item={editCompany} onClose={() => setEditCompany(null)} />}
    </>
}

const mapStateToProps = state => ({
  companies: state.firestore.companies,
  users: state.firestore.users,
})

CompanyTable.propTypes = {
  classes: PropTypes.object.isRequired,
  companies: PropTypes.array,
  deleteDocAction: PropTypes.func.isRequired,
  users: PropTypes.array,
}

export default compose(
  connect(mapStateToProps, { deleteDocAction }),
  withStyles(styles),
)(CompanyTable)
