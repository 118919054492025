import React, { useState } from 'react'
import { Grow, TextField, IconButton } from '@mui/material'
import PropTypes from 'prop-types'
import { Clear } from '@mui/icons-material'
import { withStyles } from '@mui/styles'


const styles = theme => ({
  main: {
    display: 'flex',
    flex: '1 0 auto',
  },
  searchText: {
    flex: '0.8 0',
  },
  clearIcon: {
    '&:hover': {
      color: theme.palette.error.main,
    },
  },
})

const CustomSearchRender = ({ classes, options, onHide, searchText, onSearch }) => {

  const [tmpVal, setTmpVal] = useState(searchText || '')

  return (
    <Grow appear in timeout={300}>
      <div className={classes.main}>
        <TextField
          placeholder='Press enter to run search'
          className={classes.searchText}
          InputProps={{
            'aria-label': options.textLabels.toolbar.search,
          }}
          value={tmpVal}
          onChange={e => setTmpVal(e.target.value)}
          fullWidth
          onKeyPress={e => {
            if (e.key === 'Enter') onSearch(tmpVal)
          }}
        />
        <IconButton className={classes.clearIcon} onClick={() => { onSearch(null); onHide() }}>
          <Clear />
        </IconButton>
      </div>
    </Grow>
  )
}

CustomSearchRender.propTypes = {
  classes: PropTypes.object.isRequired,
  options: PropTypes.object,
  onHide: PropTypes.func.isRequired,
  searchText: PropTypes.string,
  onSearch: PropTypes.func.isRequired,
}

export default withStyles(styles)(CustomSearchRender)
