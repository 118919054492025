import React  from 'react'
import PropTypes from 'prop-types'
import { Box } from '@mui/material'
import NavigatorDrawer from '../menu/NavigatorDrawer'
import TopBar from '../menu/TopBar'


const drawerWidth = '256px'
const topbarHeight = '55px'

const Page = ({ children, withTopBarAndMenu }) => (
  <Box sx={{ height: '100vh' }}>
    {withTopBarAndMenu && <nav><NavigatorDrawer sx={{ width: drawerWidth }} /></nav>}
    {withTopBarAndMenu && <TopBar sx={{ height: topbarHeight }} />}
    <Box sx={{ ml: withTopBarAndMenu && drawerWidth, background: '#eaeff1', mt: withTopBarAndMenu && topbarHeight, height: '-webkit-fill-available' }}>
      {children}
    </Box>
  </Box>
)

Page.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.element]),
  withTopBarAndMenu: PropTypes.bool,
}

export default Page
