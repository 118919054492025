import { FETCH_COMMITS, FETCH_MASTER_HEAD, MERGE_ERROR, MERGE_SUCCESS } from '../types'
import { request } from '@octokit/request'

export const fetchCommitAction = () => async (dispatch, getState) => {
  const result = await request('GET /repos/wake-upp/boussole-pro/commits', {
    headers: {
      authorization: 'token ' + getState().auth.profile.ghToken,
    },
    org: 'wake-upp',
    type: 'private',
  })

  if (result.status === 200)
    dispatch({
      type: FETCH_COMMITS,
      payload: result.data,
    })
}

export const fetchMasterHead = () => async (dispatch, getState) => {
  const result = await request('GET /repos/wake-upp/boussole-pro/branches/master', {
    headers: {
      authorization: 'token ' + getState().auth.profile.ghToken,
    },
  })

  if (result.status === 200)
    dispatch({
      type: FETCH_MASTER_HEAD,
      payload: result.data,
    })
}

export const mergeAction = sha => async (dispatch, getState) => {
  const result = await request('PATCH /repos/{owner}/{repo}/git/refs/{ref}', {
    headers: {
      authorization: 'token ' + getState().auth.profile.ghToken,
    },
    owner: 'wake-upp',
    repo: 'boussole-pro',
    ref: 'heads/master',
    sha,
  })

  console.log('result', result)

  if (result.status === 200)
    dispatch({
      type: MERGE_SUCCESS,
    })
  else
    dispatch({
      type: MERGE_ERROR,
    })
}
