import { mapValues } from 'lodash'
import { doc, deleteField } from 'firebase/firestore'


export const hydrateRefs = (db, data) =>
  mapValues(data, (val, index) => {
    if (val && index.includes('Refs') && val instanceof Array) return val[1].map(id => doc(db, val[0], id))
    if (val && index.includes('Ref') && val instanceof Array) return val[1]
      ? doc(db, val[0], val[1])
      : deleteField()
    else return val
  })
