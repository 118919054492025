export const SUCCESS = 'SUCCESS'
export const ERROR = 'ERROR'
export const WARNING = 'WARNING'
export const INFO = 'INFO'
export const LOADING = 'LOADING'

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LISTEN_PROFILE = 'LISTEN_PROFILE'
export const AUTH_ERROR = 'AUTH_ERROR'
export const SIGNOUT_SUCCESS = 'SIGNOUT_SUCCESS'
export const CAPTCHA_VERIFIED = 'CAPTCHA_VERIFIED'
export const CONFIRMATION_RESULT = 'CONFIRMATION_RESULT'

export const RESET_NOTIFICATIONS = 'RESET_NOTIFICATIONS'
export const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION'

export const LISTEN_COLLECTION = 'LISTEN_COLLECTION'

export const SELECT_COMPANY = 'SELECT_COMPANY'

export const FETCH_COMMITS = 'FETCH_COMMITS'
export const FETCH_MASTER_HEAD = 'FETCH_MASTER_HEAD'
export const MERGE_SUCCESS = 'MERGE_SUCCESS'
export const MERGE_ERROR = 'MERGE_ERROR'

export const UPLOAD_PROGRESS = 'UPLOAD_PROGRESS'
export const UPLOAD_ERROR = 'UPLOAD_ERROR'
export const UPLOAD_COMPLETE = 'UPLOAD_COMPLETE'
