import React, { useState } from 'react'
import { AppBar, Box, Button, Menu, MenuItem } from '@mui/material'
import Avatar from '../_library/Avatar'
import { ArrowDropDown, ExitToApp } from '@mui/icons-material'
import { useDispatch, useSelector } from 'react-redux'
import { signOutAction } from '../../store/actions/authActions'
import PropTypes from 'prop-types'


const TopBar = ({ sx }) => {
  
  const profile = useSelector(state => state.auth.profile)
  const dispatch = useDispatch()
  
  const [anchorEl, setAnchorEl] = useState(null)
  
  return (
    <AppBar color='primary' position='absolute' elevation={0} sx={{ display: 'block', ...sx }}>
      <Box sx={{ float: 'right' }}>
        <Button onClick={e => setAnchorEl(e.currentTarget)} sx={{ color: 'white' }}>
          {profile.firstname}&nbsp;
          <Avatar photoUrl={profile.photoUrl} disableClick={true} />
          <ArrowDropDown />
        </Button>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
          PaperProps={{
            sx: {
              width: 200,
            },
          }}
        >
          <MenuItem onClick={() => dispatch(signOutAction())}>
            <ExitToApp sx={{ mr: 1 }}/>Logout
          </MenuItem>
        </Menu>
      </Box>
    </AppBar>
  )
}

TopBar.propTypes = {
  sx: PropTypes.object,
}

export default TopBar
