import { Rating } from '@mui/material'
import { withStyles } from '@mui/styles'


export const StyledRating = withStyles(theme => ({
  iconFilled: {
    color: theme.palette.primary.main,
  },
  icon: {
    fontSize: 25,
  },
}))(Rating)
