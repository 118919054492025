/* eslint-disable react/display-name */
import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { withStyles } from '@mui/styles'
import MUIDataTable from 'mui-datatables'
import Loading from '../_library/Loading'
import { format } from 'date-fns'
import { TEAM_COLLECTION } from '../../_constants/globals'
import EditTeamDialog from './EditTeamDialog'
import { listenCollection } from '../../store/actions/firestoreActions'


const styles = theme => ({
  root: {
    margin: '20px auto',
    maxWidth: 1200,
  },
  circularProgressContainer: {
    marginTop: 30,
  },
  tableRow: {
    borderBottom: `1px solid ${theme.palette.text.primary}`,
  },
  clickable: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
})

const TeamTable = ({ classes, teams, listenCollection }) => {

  const [selectedTeamId, selectTeamId] = useState(null)

  useEffect(() => {
    const listener = listenCollection({
      collection: TEAM_COLLECTION,
      orderBy: [['_createdAtTime', 'asc']],
      storeAs: 'allTeams',
    })
    return () => {
      listener()
    }
  }, [listenCollection])

  const selectedTeam = useMemo(() => teams && selectedTeamId && teams.find(t => t.id === selectedTeamId), [teams, selectedTeamId])

  const data = useMemo(() =>
    teams?.map(t => [t.id, t.name, t.company?.name, t._createdAtTime, t.managerRefs[0]?.id, t.memberRefs?.length],
    ), [teams])

  if (!teams) return <Loading />
  else return (
    <div className={classes.root}>
      <MUIDataTable
        title='Teams List'
        data={data}
        columns={[
          { name: 'ID', options: { filter: false, sort: false, display: 'false' } },
          { name: 'Nom' },
          { name: 'Client',
            options: {
              filterType: 'multiselect',
            },
          },
          { name: 'CreatedAt', options: { filter: false, sort: true, customBodyRender: date => date && format(date.toDate(), 'dd-MM-yyyy') } },
          { name: 'Manager', options: { sort: true } },
          { name: 'Membres', options: { filter: false } },
        ]}
        options={{
          selectableRows: 'none',
          responsive: 'simple',
          onRowClick: row => selectTeamId(row[0]),
          rowHover: false,
          setRowProps: () => ({
            className: classes.clickable,
          }),
        }}
      />
      {selectedTeam && <EditTeamDialog team={selectedTeam} onClose={() => selectTeamId(null)} />}
    </div>
  )
}

const mapStateToProps = state => ({
  teams: state.firestore.allTeams,
})

TeamTable.propTypes = {
  classes: PropTypes.object.isRequired,
  teams: PropTypes.array,
  listenCollection: PropTypes.func.isRequired,
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, { listenCollection }),
)(TeamTable)
