import React from 'react'
import CommitTable from './CommitTable'
import { useSelector } from 'react-redux'
import Page from '../_library/Page'
import { Typography } from '@mui/material'


const DeployPage = () => {
  
  const profile = useSelector(state => state.auth.profile)
  
  return <Page withTopBarAndMenu>
    {profile.ghToken
      ? <CommitTable />
      : <Typography textAlign='center'>ghToken (GitHub Token) is not defined for current profile</Typography>
    }
  </Page>
  
}

export default DeployPage
