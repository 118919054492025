import { combineReducers } from 'redux'
import dataReducer from './dataReducer'
import notificationReducer from './notificationReducer'
import authReducer from './authReducer'
import firestoreReducer from './firestoreReducer'


export default combineReducers({
  data: dataReducer,
  auth: authReducer,
  firestore: firestoreReducer,
  notifications: notificationReducer,
})
