import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Typography, IconButton,
} from '@mui/material'
import { ONE_ONE_TOPIC_COLLECTION } from '../../_constants/globals'
import ModalEditTopic from './ModalEditTopic'
import ModalDelete from '../_library/ModalDelete'
import { listenCollection } from '../../store/actions/firestoreActions'
import useListener from '../../hooks/useListener'
import Page from '../_library/Page'
import { Delete, Edit } from '@mui/icons-material'


export const TopicPage = () => {
  
  const topics = useSelector(state => state.firestore.oneOneTopics)
  useListener(() => listenCollection({
    collection: ONE_ONE_TOPIC_COLLECTION,
    orderBy: [['order', 'asc']],
  }), [])
  
  const [edit, setEdit] = useState(null)
  const [deleteDoc, setDeleteDoc] = useState(null)
  
  return (
    <Page withTopBarAndMenu>
      <Typography variant='h1'>Categories</Typography>
      <Button variant='contained' color='primary' onClick={() => setEdit({})} sx={{ m: 1 }}>Add a topic</Button>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold' }}>ID</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Title</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Order</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {topics?.map(topic => (
              <TableRow key={topic.id}>
                <TableCell scope='row'>{topic.slug}</TableCell>
                <TableCell scope='row'>{topic.text.fr}</TableCell>
                <TableCell scope='row'>{topic.order}</TableCell>
                <TableCell sx={{ display: 'flex' }}>
                  <IconButton onClick={() => setEdit(topic)} sx={{ color: 'primary.main' }}>
                    <Edit />
                  </IconButton>
                  <IconButton onClick={() => setDeleteDoc(topic)} sx={{ color: 'secondary.main' }}>
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {edit && <ModalEditTopic topic={edit} setTopic={setEdit} />}
      {deleteDoc && <ModalDelete collection={ONE_ONE_TOPIC_COLLECTION} id={deleteDoc.id} callback={() => setDeleteDoc(null)} />}
    </Page>
  )
}

export default TopicPage
