import React from 'react'
import PropTypes from 'prop-types'
import { Navigate, useLocation } from 'react-router'
import { LOGIN } from '../../_constants/routes'
import { useSelector } from 'react-redux'
import Loading from './Loading'


const RestrictedArea = ({ children, authenticated }) => {
  const profile = useSelector(state => state.auth.profile)
  const location = useLocation()
  
  if (!profile.isLoaded) return <Loading />
  
  else if (authenticated && profile.isEmpty) return <Navigate to={LOGIN} state={{ from: location }} replace />
  
  else return children
}

RestrictedArea.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.element]),
  authenticated: PropTypes.bool,
}

export default RestrictedArea
