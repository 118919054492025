import React from 'react'
import PropTypes from 'prop-types'
import avatarTeamMember from '../../assets/avatar_team_member.svg'
import { Box } from '@mui/material'


export const Avatar = ({ photoUrl, className, sx }) =>
  <Box className={className} sx={{
    overflow: 'hidden',
    border: '2px solid',
    borderRadius: '40px',
    bgcolor: 'primary.main',
    borderColor: 'grey.100',
    width: '40px',
    height: '40px',
    zIndex: 1,
    ml: { xs: '0.8rem', md: 'auto' },
    ...sx,
  }}>
    {photoUrl
      ? <Box sx={{
        backgroundSize: 'cover',
        height: '100%',
        width: '100%',
        backgroundImage: `url('${photoUrl}')`,
      }} />
      : <Box component='img' src={avatarTeamMember} alt='undefined' sx={{ objectFit: 'cover', height: '100%' }} />
    }
  </Box>

Avatar.propTypes = {
  className: PropTypes.string,
  photoUrl: PropTypes.string,
  sx: PropTypes.object,
}

export default Avatar
