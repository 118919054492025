import React, { useEffect, Suspense } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import * as ROUTES from './_constants/routes.js'
import LoginPage from './components/auth/LoginPage'
import './i18n'
import StatPage from './components/stats/StatPage'
import DeployPage from './components/deploy/DeployPage'
import QuestionPage from './components/oneonone/QuestionPage'
import TopicPage from './components/oneonone/TopicPage'
import Notifications from './components/Notifications'
import DataPage from './components/data/DataPage'
import DebugPage from './components/debug/DebugPage'
import { CARD_TEMPLATE_COLLECTION, COMPANY_COLLECTION, TEAM_COLLECTION } from './_constants/globals'
import Loading from './components/_library/Loading'
import { listenCollection } from './store/actions/firestoreActions'
import { useSelector } from 'react-redux'
import { listenProfileAction } from './store/actions/authActions'
import useListener from './hooks/useListener'
import RestrictedArea from './components/_library/RestrictedArea'
import CampfirePage from './components/campfires/CampfirePage'


function App() {
  
  const profile = useSelector(state => state.auth.profile)
  useListener(listenProfileAction)
  useListener(() => listenCollection({
    collection: COMPANY_COLLECTION,
    orderBy: [['name', 'asc']],
  }), [profile.id], !!profile.id)
  useListener(() => listenCollection({
    collection: TEAM_COLLECTION,
    orderBy: [['name', 'asc']],
  }), [profile.id], !!profile.id)
  useListener(() => listenCollection({
    collection: CARD_TEMPLATE_COLLECTION,
    orderBy: [['order', 'asc']],
  }), [profile.id], !!profile.id)
  
  useEffect(() => {
    console.log('Environment: ' + process.env.NODE_ENV + ' ' + process.env.REACT_APP_FIREBASE_PROJECT_ID)
  }, [])
  
  return (
    <Suspense fallback={<Loading />} >
      <Routes>
        <Route exact path={ROUTES.LOGIN} element={<LoginPage />} />
        <Route exact path={ROUTES.HOME} element={<RestrictedArea authenticated><StatPage /></RestrictedArea>} />
        <Route path={ROUTES.DATA + '/*'} element={<RestrictedArea authenticated><DataPage /></RestrictedArea>} />
        <Route exact path={ROUTES.DEPLOY} element={<RestrictedArea authenticated><DeployPage /></RestrictedArea>} />
        <Route path={ROUTES.DEBUG + '/*'} element={<RestrictedArea authenticated><DebugPage /></RestrictedArea>} />
        <Route exact path={ROUTES.ONEONONEQUESTIONS} element={<RestrictedArea authenticated><QuestionPage /></RestrictedArea>} />
        <Route exact path={ROUTES.ONEONONETOPICS} element={<RestrictedArea authenticated><TopicPage /></RestrictedArea>} />
        <Route exact path={ROUTES.CAMPFIRES} element={<RestrictedArea authenticated><CampfirePage /></RestrictedArea>} />
        <Route path='*' element={<Navigate replace to={ROUTES.HOME} />} />
      </Routes>
      <Notifications />
    </Suspense>
  )
}

export default App
