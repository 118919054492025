import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from '@mui/material'
import { sortBy } from 'lodash'
import { withStyles } from '@mui/styles'


const styles = theme => ({
  root: {
    marginLeft: '10%',
    marginRight: '10%',
    marginTop: '100px',
  },
  btn: {
    marginTop: theme.spacing(1),
  },
  headCell: {
    fontWeight: 'bold',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  dialog: {
    minWidth: '50%',
  },
})

export const Answers = ({ classes, answers }) => {

  const orderedAnswers = useMemo(
    () => answers && sortBy(answers.filter(a => a.question), a => a.question?.dimension),
    [answers],
  )

  return (
    <div>
      <Typography variant='h1'>Réponses</Typography>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell className={classes.headCell}>Dimension</TableCell>
              <TableCell className={classes.headCell}>Question</TableCell>
              <TableCell className={classes.headCell}>Answer</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orderedAnswers && orderedAnswers.map(answer => (
              <TableRow key={answer.id}>
                <TableCell component='th' scope='row'>
                  {answer.question.dimension}
                </TableCell>
                <TableCell component='th' scope='row'>
                  {answer.question.text}
                </TableCell>
                <TableCell component='th' scope='row'>
                  {answer.proposal.text} ({answer.proposal.factor.toString()})
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

Answers.propTypes = {
  classes: PropTypes.object.isRequired,
  answers: PropTypes.array,
}

export default compose(
  withStyles(styles),
)(Answers)
