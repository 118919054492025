import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Select, MenuItem, InputLabel, FormControl } from '@mui/material'
import { addDocAction, listenCollection, updateDocAction } from '../../store/actions/firestoreActions'
import { ONE_ONE_QUESTION_COLLECTION, ONE_ONE_TOPIC_COLLECTION } from '../../_constants/globals'
import { useDispatch, useSelector } from 'react-redux'
import { pickBy } from 'lodash'
import useListener from '../../hooks/useListener'


const PROFILE_TYPES = [
  'CMI',
  'CAI',
  'MCI',
  'MFI',
  'FMI',
  'FAI',
  'AFI',
  'ACI',
  'CME',
  'CAE',
  'MCE',
  'MFE',
  'FME',
  'FAE',
  'AFE',
  'ACE',
]

export const ModalEditQuestion = ({ question, setEditQuestion }) => {
  
  useListener(() => listenCollection({ collection: ONE_ONE_TOPIC_COLLECTION }))
  const topics = useSelector(state => state.firestore.oneOneTopics)
  const dispatch = useDispatch()
  
  const [fr, setFr] = useState('')
  const [en, setEn] = useState('')
  const [order, setOrder] = useState(0)
  const [topic, setTopic] = useState('')
  const [onlyApplyForProfile, setOnlyApplyForProfile] = useState('')
  
  const handleClose = () => setEditQuestion(null)
  
  const handleSubmit = async e => {
    e.preventDefault()
    const object = {
      order: Number(order),
      onlyApplyForProfiles: [ onlyApplyForProfile ],
      title: { fr, en },
      oneOneTopicRef: topic && [ONE_ONE_TOPIC_COLLECTION, topic],
    }
    return question.id
      ? dispatch(updateDocAction(ONE_ONE_QUESTION_COLLECTION, question?.id, pickBy(object))).then(handleClose)
      : dispatch(addDocAction(ONE_ONE_QUESTION_COLLECTION, object)).then(handleClose)
  }
  
  // Load edit question properties
  useEffect(() => {
    if (Object.keys(question).length === 0) return
    setFr(question.title.fr)
    setEn(question.title.en)
    setOrder(question.order)
    setTopic(question.oneOneTopicRef.id)
    question.onlyApplyForProfiles && setOnlyApplyForProfile(question.onlyApplyForProfiles[0])
  }, [question])
  
  return (
    <Dialog open onClose={handleClose}>
      <form onSubmit={handleSubmit}>
        <DialogTitle>{question.id ? `Edit question ${question.id}` : 'Create question'}</DialogTitle>
        <DialogContent>
          <TextField
            label='Order'
            type='number'
            value={order}
            onChange={event => setOrder(event.target.value)}
            sx={{ my: 1 }}
          />
          <TextField
            id='qM'
            label='Question fr'
            value={fr}
            onChange={event => setFr(event.target.value)}
            fullWidth
            sx={{ my: 1 }}
          />
          <TextField
            id='qF'
            label='Question en'
            value={en}
            onChange={event => setEn(event.target.value)}
            fullWidth
            sx={{ my: 1 }}
          />
          {topics && <FormControl fullWidth sx={{ my: 1 }}>
            <InputLabel id='apply-for-topic-label'>Sujet</InputLabel>
            <Select
              id='apply-for-topic'
              label='Sujet'
              value={topic}
              onChange={event => setTopic(event.target.value)}
            >
              {topics.map(item => <MenuItem key={item.id} value={item.id}>{item.slug}</MenuItem>)}
            </Select>
          </FormControl>}
          <FormControl fullWidth sx={{ my: 1 }}>
            <InputLabel id='apply-for-profile-label'>Apply for profile</InputLabel>
            <Select
              id='apply-for-profile'
              label='Apply for profile'
              value={onlyApplyForProfile}
              onChange={event => setOnlyApplyForProfile(event.target.value)}
            >
              {PROFILE_TYPES.map(item => <MenuItem key={item} value={item}>{item}</MenuItem>)}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='secondary'>Cancel</Button>
          <Button type='submit' color='secondary'>Save</Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

ModalEditQuestion.propTypes = {
  question: PropTypes.object,
  setEditQuestion: PropTypes.func.isRequired,
}

export default ModalEditQuestion
