import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import * as _ from 'lodash'
import { Box } from '@mui/material'
import classNames from 'classnames'
import Avatar from './AvatarGraph'
import clsx from 'clsx'
import { useSelector } from 'react-redux'
import { withStyles } from '@mui/styles'


const styles = theme => ({
  root: {
    height: 500,
    width: 500,
    margin: '0 auto 30px',
    padding: 50,
    position: 'relative',
  },
  scale: {
    [theme.breakpoints.down('sm')]: {
      marginLeft: '-20%',
      transform: 'scale(0.7)',
      marginTop: -50,
      marginBottom: -50,
    },
  },
  table: {
    float: 'left',
    width: '50%',
    height: '50%',
    zIndex: 2,
    '&:nth-child(1)': {
      borderBottom: 'solid grey 2px',
      borderRight: 'solid grey 2px',
    },
    '&:nth-child(2)': {
      borderBottom: 'solid grey 2px',
    },
    '&:nth-child(3)': {
      borderRight: 'solid grey 2px',
    },
  },
  label: {
    position: 'absolute',
    textAlign: 'center',
    width: 100,
    color: 'grey',
    fontWeight: 700,
  },
  topLabel: {
    top: 0,
    left: '41%',
  },
  rightLabel: {
    right: -20,
    transform: 'rotate(90deg)',
    top: '41%',
  },
  bottomLabel: {
    bottom: -20,
    left: '41%',
  },
  leftLabel: {
    left: -20,
    transform: 'rotate(-90deg)',
    top: '41%',
  },
  heart: {
    position: 'absolute',
    width: 200,
    height: 200,
    borderRadius: 100,
    background: 'radial-gradient(circle, rgba(255,0,85,.9) 3%, rgba(255,0,85,0) 65%)',
    zIndex: 3,
  },
  span: {
    position: 'absolute',
    height: 20,
    width: 20,
    border: 'solid lightGrey 4px',
    borderRadius: 10,
    backgroundColor: 'white',
    zIndex: 4,
  },
  triangle: {
    position: 'absolute',
    zIndex: 1,
    height: 0,
    width: 0,
    border: 'solid transparent',
  },
})

export const PersonalityGraph = ({ classes, users, onClickAvatar, displayWeb, displayAvatars, displayHeart, className, id, style }) => {
  
  const profile = useSelector(state => state.auth.profile)

  const avatarRadius = useMemo(() => {
    if (users.length >= 20) return 10
    else if (users.length <= 5) return 30
    else return 30 - users.length
  }, [users.length])

  const { heartCoords, usersWithCoords } = useMemo(() => {
    if (!users || !users[0]) return null

    /**
     * Graph needs t have a fixed size in order to calculate all element sizes and positions
     * @type {number}
     */
    const graphSize = 200

    /**
     * The radius of the blurred red dot
     * @type {number}
     */
    const heartRadius = 100

    /**
     * The radius of the parameter value dots
     * @type {number}
     */
    const spanRadius = 11

    /**
     * The padding of the graph
     * @type {number}
     */
    const padding = 50

    /**
     * target indicates at what percentage min and max parameters wil be printed
     * This is only used in case of a single user graph and displayWeb is set to true
     * @type {{ min: number, max: number }}
     */
    const target = { min: 30, max: 90 }

    /**
     * The graph is scaled to prevent it from showing every avatar in the middle and to make sure they are all displayed
     * @type {number}
     */
    const graphScale = _.reduce(users, (acc, val) => {
      let max = acc
      if (Math.abs(val.testResults.X) > max) max = Math.abs(val.testResults.X)
      if (Math.abs(val.testResults.Y) > max) max = Math.abs(val.testResults.Y)
      return max
    }, 0) + 5

    /**
     * Each user is been given some coordinates based on graphScale of the following scheme
     *
     * @type {{
     *  x,
     *  y,
     *  operations: {x, y},
     *  management: {x, y},
     *  strategy: {x, y},
     *  creativity: {x, y},
     *  triangle: {top: *, left: *, bottom: *, center, right: *}
     * }[]}
     *
     * triangle represent the main shape which is actually made of two css triangles
     */
    const usersWithCoords = users.map(member => {
      const requiredMin = graphSize * target.min / 100
      const requiredMax = graphSize * target.max / 100
      const { min: webMin, max: webMax } = _.reduce(_.pick(member.testResults, ['strategy', 'creativity', 'management', 'operations']), (acc, val) => {
        if (Math.abs(val) < acc.min) acc.min = Math.abs(val)
        if (Math.abs(val) > acc.max) acc.max = Math.abs(val)
        return acc
      }, { min: 100, max: 0 })
      const rescale = number => (number - webMin) * (requiredMax - requiredMin) / (webMax - webMin + .00001) + requiredMin
      return {
        ...member,
        x: member.testResults.X,
        y: member.testResults.Y,
        strategy: {
          x: 0,
          y: rescale(member.testResults.strategy),
        },
        creativity: {
          x: rescale(member.testResults.creativity),
          y: 0,
        },
        operations: {
          x: 0,
          y: - rescale(member.testResults.operations),
        },
        management: {
          x: - rescale(member.testResults.management),
          y: 0,
        },
        triangle: {
          center: graphSize + padding,
          top: rescale(member.testResults.strategy),
          right: rescale(member.testResults.creativity),
          bottom: rescale(member.testResults.operations),
          left: rescale(member.testResults.management),
        },
      }
    })

    /**
     * Heart coords is the barycenter of the four coordinates
     */
    const heartCoords =
      _.chain(usersWithCoords)
        .reduce((acc, val) => displayWeb
          ? ({
            x: parseFloat(acc.x) + (val.creativity.x + val.management.x) / 4,
            y: parseFloat(acc.y) + (val.strategy.y + val.operations.y) / 4,
          })
          : ({
            x: parseFloat(acc.x) + val.x,
            y: parseFloat(acc.y) + val.y,
          }), { x: 0, y: 0 })
        .mapValues(val => val / users.length)
        .value()

    /**
     * Converts orthonormal coordinates into css coordinates where the origin is in the top left corner
     * @param x
     * @param y
     * @param radius
     * @param scale
     * @returns {{ x: number, y: number }}
     */
    const translateIntoCSS = ({ x, y }, radius, scale = graphSize) =>
      ({
        x: graphSize - radius + padding + (x * graphSize / scale),
        y: graphSize - radius + padding - (y * graphSize / scale),
      })

    return {
      usersWithCoords: usersWithCoords.map(user => {
        user.x = translateIntoCSS(user, avatarRadius, graphScale).x
        user.y = translateIntoCSS(user, avatarRadius, graphScale).y
        user.creativity = translateIntoCSS(user.creativity, spanRadius)
        user.management = translateIntoCSS(user.management, spanRadius)
        user.operations = translateIntoCSS(user.operations, spanRadius)
        user.strategy = translateIntoCSS(user.strategy, spanRadius)
        return user
      }),
      heartCoords: translateIntoCSS(heartCoords, heartRadius, displayWeb ? graphSize : graphScale),
    }
  }, [users, displayWeb, avatarRadius]) || {}

  if (!usersWithCoords || usersWithCoords.length === 0) return null
  else return (
    <div className={clsx(classes.scale, className)} id={id || 'personalityGraph'} style={style}>
      <div className={classes.root}>
        <Box height='50%' className={classes.table} />
        <Box height='50%' className={classes.table} />
        <Box height='50%' className={classes.table} />
        <Box height='50%' className={classes.table} />
        <p className={classNames(classes.label, classes.topLabel)}>Stratégie</p>
        <p className={classNames(classes.label, classes.rightLabel)}>Créativité</p>
        <p className={classNames(classes.label, classes.bottomLabel)}>Opérations</p>
        <p className={classNames(classes.label, classes.leftLabel)}>Gestion</p>
        {displayHeart && <div className={classes.heart} style={{ top: heartCoords.y, left: heartCoords.x }} />}
        {displayWeb && <>
          <span
            className={classes.span}
            style={{
              top: usersWithCoords[0].strategy.y,
              left: usersWithCoords[0].strategy.x,
            }}
          />
          <span
            className={classes.span}
            style={{
              top: usersWithCoords[0].creativity.y,
              left: usersWithCoords[0].creativity.x,
            }}
          />
          <span
            className={classes.span}
            style={{
              top: usersWithCoords[0].operations.y,
              left: usersWithCoords[0].operations.x,
            }}
          />
          <span
            className={classes.span}
            style={{
              top: usersWithCoords[0].management.y,
              left: usersWithCoords[0].management.x,
            }}
          />
          <div
            className={classes.triangle}
            style={{
              top: usersWithCoords[0].triangle.center - usersWithCoords[0].triangle.top,
              left: usersWithCoords[0].triangle.center,
              borderLeft: 'solid rgba(52, 49, 81, .85)',
              borderLeftWidth: usersWithCoords[0].triangle.right,
              borderTopWidth: usersWithCoords[0].triangle.top,
              borderBottomWidth: usersWithCoords[0].triangle.bottom,
            }}
          />
          <div
            className={classes.triangle}
            style={{
              top: usersWithCoords[0].triangle.center - usersWithCoords[0].triangle.top,
              right: usersWithCoords[0].triangle.center,
              borderRight: 'solid rgba(52, 49, 81, .85)',
              borderRightWidth: usersWithCoords[0].triangle.left,
              borderTopWidth: usersWithCoords[0].triangle.top,
              borderBottomWidth: usersWithCoords[0].triangle.bottom,
            }}
          />
        </>}
        {displayAvatars && usersWithCoords.map(user => <Avatar
          photoUrl={user.photoUrl}
          style={{ top: user.y, left: user.x, zIndex: 5 }}
          key={user.id || profile.id}
          onClick={() => onClickAvatar(user.id || profile.id)}
          firstname={user.firstname}
          size={avatarRadius * 2}
        />)}
      </div>
    </div>
  )
}

PersonalityGraph.propTypes = {
  classes: PropTypes.object.isRequired,
  users: PropTypes.array.isRequired,
  onClickAvatar: PropTypes.func,
  displayWeb: PropTypes.bool,
  displayAvatars: PropTypes.bool,
  displayHeart: PropTypes.bool,
  className: PropTypes.string,
  id: PropTypes.string,
  style: PropTypes.object,
}

export default compose(
  withStyles(styles),
)(PersonalityGraph)
