import ingenieuxBadge from '../assets/TEAM_CATEGORY_IMAGES/ingenieux.png'
import audacieuxBadge from '../assets/TEAM_CATEGORY_IMAGES/audacieux.png'
import mcGyverBadge from '../assets/TEAM_CATEGORY_IMAGES/McGyvers.png'
import dirigeantsBadge from '../assets/TEAM_CATEGORY_IMAGES/dirigeants.png'
import inventeurBadge from '../assets/TEAM_CATEGORY_IMAGES/inventeurs.png'
import pionnierBadge from '../assets/TEAM_CATEGORY_IMAGES/pionniers.png'
import artisteBadge from '../assets/TEAM_CATEGORY_IMAGES/artistes.png'
import explorateurBadge from '../assets/TEAM_CATEGORY_IMAGES/explorateurs.png'
import excellenceBadge from '../assets/TEAM_CATEGORY_IMAGES/excellence.png'
import tacticiensBadge from '../assets/TEAM_CATEGORY_IMAGES/tactitiens.png'
import diplomatesBadge from '../assets/TEAM_CATEGORY_IMAGES/diplomates.png'
import familyBadge from '../assets/TEAM_CATEGORY_IMAGES/famille.png'
import humanistesBadge from '../assets/TEAM_CATEGORY_IMAGES/humanistes.png'
import ambassadeurBadge from '../assets/TEAM_CATEGORY_IMAGES/ambassadeurs.png'
import chaleureuxBadge from '../assets/TEAM_CATEGORY_IMAGES/chaleureux.png'
import entraineurBadge from '../assets/TEAM_CATEGORY_IMAGES/entraineurs.png'

export const getTeamProfileType = teamMembers => {
  if (!teamMembers || !teamMembers[0]) return
  const result = teamMembers
    .reduce(
      (acc, val) => ({ testResults: { X: acc.testResults.X + val.testResults.X, Y: acc.testResults.Y + val.testResults.Y } } ),
      { testResults: { X: 0, Y: 0 } },
    )
  const X = result.testResults.X / teamMembers.length
  const Y = result.testResults.Y / teamMembers.length
  const absX = Math.abs(X)
  const absY = Math.abs(Y)
  let first, second
  if (X > 0) {
    if (Y > 0) {
      first = 'C'
      second = absX > absY ? 'M' : 'A'
    } else {
      first = 'M'
      second = absX > absY ? 'C' : 'F'
    }
  } else {
    if (Y > 0) {
      first = 'A'
      second = absX > absY ? 'F' : 'C'
    } else {
      first = 'F'
      second = absX > absY ? 'A' : 'M'
    }
  }
  const third = teamMembers.filter(el => el.profileType.split('')[2] === 'I').length > teamMembers.length / 2 ? 'I' : 'E'
  return first + second + third
}

export const getTeamTitle = profileType => {
  switch (profileType) {
    case 'ACI': return 'Les ingénieux'
    case 'ACE': return 'Les audacieux'
    case 'AFI': return 'Les MacGyvers'
    case 'AFE': return 'Les dirigeants'
    case 'CAI':	return 'Les inventeurs'
    case 'CAE':	return 'Les pionniers'
    case 'CMI':	return 'Les artistes'
    case 'CME':	return 'Les explorateurs'
    case 'FAI':	return 'Les artisans d\'excellence'
    case 'FAE':	return 'Les tacticiens'
    case 'FMI':	return 'Les diplomates'
    case 'FME':	return 'La famille'
    case 'MCI':	return 'Les humanistes'
    case 'MCE':	return 'Les ambassadeurs'
    case 'MFI':	return 'Les chaleureux'
    case 'MFE':	return 'Les entraîneurs'
    default: throw Error('Unknown profile')
  }
}

export const getTeamBadge = profileType => {
  switch (profileType) {
    case 'ACI': return ingenieuxBadge
    case 'ACE': return audacieuxBadge
    case 'AFI': return mcGyverBadge
    case 'AFE': return dirigeantsBadge
    case 'CAI':	return inventeurBadge
    case 'CAE':	return pionnierBadge
    case 'CMI':	return artisteBadge
    case 'CME':	return explorateurBadge
    case 'FAI':	return excellenceBadge
    case 'FAE':	return tacticiensBadge
    case 'FMI':	return diplomatesBadge
    case 'FME':	return familyBadge
    case 'MCI':	return humanistesBadge
    case 'MCE':	return ambassadeurBadge
    case 'MFI':	return chaleureuxBadge
    case 'MFE':	return entraineurBadge
    default: throw Error('Unknown profile')
  }
}
