import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, ClickAwayListener, Tooltip, CircularProgress } from '@mui/material'
import { Delete } from '@mui/icons-material'


function DeleteButton({ deleteAction }) {

  const [activated, setActivated] = useState(false)
  const [submitted, setSubmitted] = useState(false)

  const action = () => {
    setSubmitted(true)
    deleteAction()
  }

  return (
    <React.Fragment>
      { activated
        ? <ClickAwayListener onClickAway={() => setActivated(false)}>
          { submitted
            ? <CircularProgress color='inherit' size='1.5rem' />
            : <Button style={{ color: 'red', margin: 5 }} onClick={action}>Supprimer ?</Button>
          }
        </ClickAwayListener>
        : <Tooltip title={'Supprimer'}>
          <Button style={{ minWidth: '40px' }} onClick={() => setActivated(true)}>
            <Delete/>
          </Button>
        </Tooltip>
      }
    </React.Fragment>
  )
}

DeleteButton.propTypes = {
  deleteAction: PropTypes.func.isRequired,
}

export default DeleteButton
