import React from 'react'
import { Backdrop, CircularProgress } from '@mui/material'


const Loading = () => (
  <Backdrop open sx={{
    zIndex: theme => theme.zIndex.drawer + 1,
    bgcolor: '#f8f8f8',
    color: '#f8f8f8',
  }}>
    <CircularProgress color='inherit' />
  </Backdrop>
)

export default Loading
