import React, { useMemo } from 'react'
import UserFinder from './UserFinder'
import EmailTable from './EmailTable'
import TabbedPage from '../_library/TabbedPage'


const DataPage = () => {
  
  const tabs = useMemo(() => [
    {
      key: 'emails',
      label: 'Emails',
      route: 'emails',
      content: <EmailTable />,
    },
    {
      key: 'users',
      label: 'Users',
      route: 'users',
      content: <UserFinder />,
    },
  ], [])
  
  return (<TabbedPage tabs={tabs} />)
}

export default DataPage
