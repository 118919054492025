import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { Grid, CircularProgress, Button } from '@mui/material'
import MUIDataTable from 'mui-datatables'
import { fetchCommitAction, fetchMasterHead, mergeAction } from "../../store/actions/deployActions"
import { formatDistance } from 'date-fns'
import { withStyles } from '@mui/styles'


const styles = theme => ({
  tableRow: {
    borderBottom: `1px solid ${theme.palette.text.primary}`,
  },
  tableCell: {
    minWidth: 0,
    margin: 0,
    padding: 0,
    width: 20,
  },
  textField: {
    width: '100%',
    maxWidth: 400,
    marginBottom: 30,
  },
  circularProgressContainer: {
    marginTop: 30,
  },
  emptyTable: {
    width: '100%',
    textAlign: 'center',
    marginTop: '2rem',
    color: 'dimgray',
  },
})

export const CommitTable = ({ classes, commits, fetchCommitAction, fetchMasterHead, masterHeadSha, merged, mergeAction }) => {

  useEffect(() => {
    fetchCommitAction()
    fetchMasterHead()
  }, [])

  const data = useMemo(() => commits && commits.reduce((acc, val) => {
    if (val) {
      const { commit: { author: { date }, message, verification: { verified } }, html_url: url, sha } = val // eslint-disable-line camelcase
      acc.push({
        sha,
        date,
        message,
        verified: verified ? '✔' : '️✖',
        url,
        branch: masterHeadSha === sha && !merged ? 'master' : '',
      })
    }
    return acc
  }, []), [commits, masterHeadSha, merged])

  return (
    <>
      {!data && <Grid container item xs={12} justifyContent='center' className={classes.circularProgressContainer}>
        <CircularProgress size={20}/>
      </Grid>}

      {data && <MUIDataTable
        data={data}
        columns={[
          { name: 'sha', label: 'Key', options: { filter: true, display: 'false' } },
          { name: 'date', label: 'Date', options: { filter: true, customBodyRender: (value, tableMeta) => // eslint-disable-line react/display-name
            <a href={data[tableMeta.rowIndex].url}>{formatDistance(new Date(value), new Date(), { addSuffix: true })}</a>,
          } },
          { name: 'message', label: 'Message', options: { filter: true, sort: true } },
          { name: 'verified', label: 'Verified', options: { filter: true, sort: true } },
          { name: 'branch', label: 'Branch', options: { filter: true, sort: true, customBodyRender: (value, tableMeta) =>
            tableMeta.rowIndex === 0
              ? value === 'master' || merged
                ? <Button color='primary' variant='outlined' onClick={() => {window.location.href = 'https://github.com/wake-upp/boussole-pro/actions'}}>
                        Up to date
                </Button>
                : <Button color='secondary' variant='contained' onClick={() => mergeAction(tableMeta.rowData[0])}>DEPLOY</Button>
              : value,
          } },
        ]}
        options={{
          selectableRows: 'none',
          responsive: 'simple',
        }}
      />}
    </>
  )
}

const mapStateToProps = state => ({
  commits: state.data.commits,
  masterHeadSha: state.data.masterHeadSha,
  merged: state.data.merged,
})

CommitTable.propTypes = {
  classes: PropTypes.object.isRequired,
  commits: PropTypes.array,
  masterHeadSha: PropTypes.string,
  merged: PropTypes.bool,
  fetchCommitAction: PropTypes.func.isRequired,
  fetchMasterHead: PropTypes.func.isRequired,
  mergeAction: PropTypes.func.isRequired,
}

export default compose(
  connect(mapStateToProps, { fetchCommitAction, fetchMasterHead, mergeAction }),
  withStyles(styles),
)(CommitTable)
