import React, { useState } from 'react'
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, Typography, Select, MenuItem, Box } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { listenCollection, createCompanyAction } from '../../store/actions/firestoreActions'
import { USER_COLLECTION } from '../../_constants/globals'
import useListener from '../../hooks/useListener'


const NewCompanyButton = () => {
  
  const consultants = useSelector(state => state.firestore.consultants)
  const dispatch = useDispatch()
  useListener(() => listenCollection({
    collection: USER_COLLECTION,
    where: [['roles', 'array-contains', 'consultant']],
    storeAs: 'consultants',
  }))
  
  const [open, setOpen] = useState(false)
  const [name, setName] = useState(null)
  const [street, setStreet] = useState(null)
  const [zipcode, setZipcode] = useState(null)
  const [city, setCity] = useState(null)
  const [licences, setLicences] = useState(null)
  const [externalLicences, setExternalLicences] = useState(null)
  const [managerId, setManagerId] = useState(null)
  const [consultantId, setConsultantId] = useState('none')
  
  const handleSubmit = e => {
    e.preventDefault()
    return dispatch(createCompanyAction(
      name,
      managerId,
      Number(licences),
      Number(externalLicences),
      { street, zipcode, city },
      consultantId === 'none' ? null : [USER_COLLECTION, consultantId],
    ))
      .then(() => setOpen(false))
  }
  
  return (
    <>
      <Button variant='contained' color='primary' onClick={() => setOpen(true)}>Nouveau compte client</Button>
      <Dialog open={open} onClose={() => setOpen(false)} aria-labelledby='form-dialog-title'>
        <form id='create-company-form' onSubmit={handleSubmit}>
          <DialogTitle id='form-dialog-title'>Nouveau compte client</DialogTitle>
          <DialogContent>
            <TextField
              margin='dense'
              id='name'
              label='Nom du client / Raison sociale'
              type='text'
              fullWidth
              value={name || ''}
              onChange={e => setName(e.target.value)}
            />
            <Typography variant='h6' mt={4}>Adresse</Typography>
            <Box sx={{ pl: 4, mb: 4 }}>
              <TextField
                margin='dense'
                id='street'
                label='Rue'
                type='text'
                fullWidth
                value={street || ''}
                onChange={e => setStreet(e.target.value)}
              />
              <TextField
                margin='dense'
                id='zipcode'
                label='Code postal'
                type='text'
                fullWidth
                value={zipcode || ''}
                onChange={e => setZipcode(e.target.value)}
              />
              <TextField
                margin='dense'
                id='city'
                label='Ville'
                type='text'
                fullWidth
                value={city || ''}
                onChange={e => setCity(e.target.value)}
              />
            </Box>
            <TextField
              margin='dense'
              id='invitations'
              label='Nombre de licences'
              type='number'
              fullWidth
              value={licences || 0}
              onChange={e => setLicences(parseInt(e.target.value))}
            />
            <TextField
              margin='dense'
              id='ext-invitations'
              label='Invitations externes (consultants)'
              type='number'
              fullWidth
              value={externalLicences || 0}
              onChange={e => setExternalLicences(parseInt(e.target.value))}
            />
            <TextField
              margin='dense'
              id='manager'
              label='Manager Id'
              type='text'
              fullWidth
              value={managerId || ''}
              onChange={e => setManagerId(e.target.value)}
            />
            <Select
              id='consultant-select'
              label='Consultant'
              value={consultantId}
              onChange={e => setConsultantId(e.target.value)}
              margin='dense'
              sx={{ mt: 1 }}
            >
              <MenuItem value='none'><em>Pas de consultant</em></MenuItem>
              {consultants?.map(consultant =>
                <MenuItem key={consultant.id} value={consultant.id}>
                  {`${consultant.company?.name} - ${consultant.firstname}`}
                </MenuItem>,
              )}
            </Select>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(false)} color='primary'>Annuler</Button>
            <Button type='submit' color='primary'>Valider</Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  )
}

export default NewCompanyButton
