import { ACCESS_LEVEL_ADMIN, COMPANY_COLLECTION, USER_COLLECTION } from '../../_constants/globals'
import { ERROR, LISTEN_COLLECTION, SUCCESS } from '../types'
import { doc, query, collection, where, onSnapshot, orderBy, getDoc, addDoc, serverTimestamp, updateDoc,
  deleteDoc, getDocs, limitToLast, setDoc, runTransaction } from 'firebase/firestore'
import { hydrateRefs } from '../../_helpers/firestoreHelpers'
import { pick } from 'lodash'


/**
 * Set a listener on Firestore collection
 * @param {string} collectionParam
 * @param {string[]} whereParam
 * @param {string[]} sorts
 * @param {number} limit
 * @param {string} storeAs
 * @param {string} debugKey
 */
export const listenCollection = ({ collection: collectionParam, where: whereParam = [], orderBy: orderParam = [], limit, storeAs, debugKey }) => (dispatch, getState, { db }) => {
  debugKey && console.log('listenCollection DEBUG', debugKey)
  debugKey && console.log('collectionParam', collectionParam)
  debugKey && console.log('where', whereParam)
  debugKey && console.log('orderBy', orderParam)
  debugKey && console.log('storeAs', storeAs)
  const constraints = []
  whereParam.forEach(filter => constraints.push(
    filter[0].includes('Ref')
      ? where(filter[0], filter[1], doc(db, filter[2], filter[3]))
      : where(...filter),
  ))
  orderParam.forEach(sort => constraints.push(orderBy(...sort)))
  if (limit) constraints.push(limitToLast(limit))
  debugKey && console.log('constraints', constraints)
  return onSnapshot(query(collection(db, collectionParam), ...constraints), snap => {
    debugKey && console.log(`New ${collectionParam} snapshot. length: `, snap.size)
    return dispatch({
      type: LISTEN_COLLECTION,
      collection: storeAs || collectionParam,
      payload: snap.docs.map(doc => ({ id: doc.id, ...doc.data() })),
    })
  })
}

/**
 * Get collection data from firestore
 * @param {string} collectionStr
 * @param {string[][]} filters
 */
export const fetchCollection = ({ collection: collectionStr, where: filters = [] }) => (dispatch, getState, { db }) => {
  const q = query(
    collection(db, collectionStr),
    ...filters.map(filter =>
      filter[0].includes('Ref')
        ? where(filter[0], filter[1], doc(db, filter[2], filter[3]))
        : where(...filter),
    ),
  )
  return getDocs(q).then(snap => {
    dispatch({
      type: LISTEN_COLLECTION,
      collection: collectionStr,
      payload: snap.docs.map(doc => ({ id: doc.id, ...doc.data() })),
    })
    return snap.docs.map(doc => ({ id: doc.id, ...doc.data() }))
  })
}

/**
 * Get only one document
 * @param {object | string} ref
 * @param {string} id
 */
export const getDocAction = (ref, id) => (dispatch, getState, { db }) => id
  ? getDoc(doc(db, ref, id)).then(doc => ({ id: doc.id, ...doc.data() }))
  : getDoc(ref).then(doc => ({ id: doc.id, ...doc.data() }))

/**
 * @param {string} collectionStr
 * @param {object} data
 * @param {boolean} notification
 */
export const addDocAction = (collectionStr, data, notification = false) => (dispatch, getState, { db }) =>
  addDoc(collection(db, collectionStr), {
    ...hydrateRefs(db, data),
    _createdAtTime: serverTimestamp(),
    _createdBy: pick(getState().auth.profile, ['id', 'firstname', 'email']),
    _origin: window.location.origin.replace('3001', '3000'),
  })
    .then(ref => {
      if (notification)
        dispatch({
          type: SUCCESS,
          payload: 'notifications.addDoc.success',
        })
      return ref
    })
    .catch(err => {
      if (notification)
        dispatch({
          type: ERROR,
          payload: 'notifications.addDoc.error',
        })
      console.error(err)
    })

/**
 * @param {string} collectionStr
 * @param {string} id
 * @param {object} data
 * @param {boolean} notification
 */
export const updateDocAction = (collectionStr, id, data, notification = false) => (dispatch, getState, { db }) =>
  updateDoc(doc(db, collectionStr, id), {
    ...hydrateRefs(db, data),
    _updatedAtTime: serverTimestamp(),
  })
    .then(ref => {
      if (notification)
        dispatch({
          type: SUCCESS,
          payload: 'notifications.updateDoc.success',
        })
      return ref
    })
    .catch(err => {
      if (notification)
        dispatch({
          type: ERROR,
          payload: 'notifications.updateDoc.error',
        })
      console.error(err)
    })

/**
 * @param {string} collectionStr
 * @param {string} id
 * @param {object} data
 * @param {boolean} notification
 */
export const setDocAction = (collectionStr, id, data, notification = false) => (dispatch, getState, { db }) =>
  setDoc(doc(db, collectionStr, id), {
    ...hydrateRefs(db, data),
    _updatedAtTime: serverTimestamp(),
  })
    .then(ref => {
      if (notification)
        dispatch({
          type: SUCCESS,
          payload: 'notifications.updateDoc.success',
        })
      return ref
    })
    .catch(err => {
      if (notification)
        dispatch({
          type: ERROR,
          payload: 'notifications.updateDoc.error',
        })
      console.error(err)
    })

/**
 * @param {string} collectionStr
 * @param {string} id
 * @param {boolean} notification
 */
export const deleteDocAction = (collectionStr, id, notification = false) => (dispatch, getState, { db }) =>
  deleteDoc(doc(db, collectionStr, id))
    .then(res => {
      if (notification)
        dispatch({
          type: SUCCESS,
          payload: 'notifications.deleteDoc.success',
        })
      return res
    })
    .catch(err => {
      if (notification)
        dispatch({
          type: ERROR,
          payload: 'notifications.deleteDoc.error',
        })
      console.error(err)
    })

/**
 * Get stats (number of : test finished, premium users, invited users, ready for test users)
 */
export const getStats = () => async (dispatch, getState, { db }) => {
  const usersSnapShot = await getDocs(collection(db, USER_COLLECTION))
  const usersDocs = usersSnapShot.docs
  return {
    numberTestFinished: usersDocs.filter(user => user.get('testResults')).length,
    numberPremium: usersDocs.filter(user => user.get('plan') === 'premium').length,
    numberInvited: usersDocs.filter(user => user.get('plan') === 'invited').length,
    numberReadyForTest: usersDocs.filter(user => user.get('readyForTest') === true).length,
  }
}

/**
 * @param {string} name
 * @param {string} managerId
 * @param {number} licences
 * @param {number} externalLicences
 * @param {object} address
 * @param {object} consultantRef
 */
export const createCompanyAction = (name, managerId, licences, externalLicences, address, consultantRef) => async (dispatch, getState, { db }) =>
  runTransaction(db, async transaction => {
    const managerRef = doc(db, USER_COLLECTION, managerId)
    const companyRef = doc(collection(db, COMPANY_COLLECTION))
    transaction.set(companyRef, {
      _createdAtTime: serverTimestamp(),
      _createdBy: pick(getState().auth.profile, ['id', 'firstname', 'email']),
      _origin: window.location.origin,
      name,
      licences,
      externalLicences,
      address,
      managerRef,
      consultantRef,
    })
    transaction.update(managerRef, {
      _updatedAt: serverTimestamp(),
      companyRef,
      accessLevel: ACCESS_LEVEL_ADMIN,
    })
  })
