import React from 'react'
import { Typography } from '@mui/material'
import Stats from './Stats'
import Page from '../_library/Page'
import { useSelector } from 'react-redux'


const StatPage = () => {
  
  const profile = useSelector(state => state.auth.profile)
  
  return (
    <Page withTopBarAndMenu>
      <Typography variant='h1'>Welcome {profile.firstname}</Typography>
      <Stats />
    </Page>
  )
}

export default StatPage
