import React from 'react'
import { AppBar, Grid, Tab, Tabs, Toolbar, Typography } from '@mui/material'
import PropTypes from 'prop-types'


const Header = ({ setValue, value = 0, title, tabs }) => (
  <>
    <AppBar component='div' color='primary' position='sticky' elevation={0} sx={{ zIndex: 0 }}>
      <Toolbar>
        <Grid container alignItems='center' spacing={1}>
          <Grid item xs>
            <Typography color='inherit' variant='h5' component='h1'>
              {title}
            </Typography>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
    <AppBar component='div' color='primary' position='sticky' elevation={0} sx={{ zIndex: 0 }}>
      <Tabs value={value} onChange={(_, newValue) => setValue(newValue)} textColor='inherit'>
        {tabs.map(tab => (
          <Tab key={'tab' + tab.key} label={tab.label} id={'tab-' + tab.key} value={tab.key} />
        ))}
      </Tabs>
    </AppBar>
  </>
)

Header.propTypes = {
  setValue: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  tabs: PropTypes.array.isRequired,
}

export default Header
