import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Select, MenuItem, Typography, Box } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { listenCollection, updateDocAction } from '../../store/actions/firestoreActions'
import { COMPANY_COLLECTION, ROLE_CONSULTANT, USER_COLLECTION } from '../../_constants/globals'
import LogoInput from '../_library/LogoInput'
import useListener from '../../hooks/useListener'
import { DatePicker } from '@mui/x-date-pickers'


const CompanyDetailsDialog = ({ item, onClose }) => {
  
  const dispatch = useDispatch()
  const consultants = useSelector(state => state.firestore.consultants)
  useListener(() => listenCollection({
    collection: USER_COLLECTION,
    where: [['roles', 'array-contains', ROLE_CONSULTANT]],
    storeAs: 'consultants',
  }))
  
  const [name, setName] = useState(item.name)
  const [street, setStreet] = useState(item.address?.street || '')
  const [zipcode, setZipcode] = useState(item.address?.zipcode || '')
  const [city, setCity] = useState(item.address?.city || '')
  const [licences, setLicences] = useState(item.licences)
  const [externalLicences, setExternalLicences] = useState(item.externalLicences)
  const [consultantId, setConsultantId] = useState(item.consultantUserRef?.id || 'none')
  
  const handleSubmit = e => {
    e.preventDefault()
    dispatch(updateDocAction(COMPANY_COLLECTION, item.id, {
      name,
      address: {
        street,
        zipcode,
        city,
      },
      licences: Number(licences),
      externalLicences: Number(externalLicences),
      consultantUserRef: consultantId === 'none' ? null : [USER_COLLECTION, consultantId],
    })).then(onClose)
  }
  
  return (
    <Dialog open onClose={onClose} sx={{ overflowY: 'initial' }}>
      <form onSubmit={handleSubmit}>
        <DialogTitle id='form-dialog-title'>Détails</DialogTitle>
        <DialogContent>
          <TextField
            margin='dense'
            id='name'
            label='Nom du client / Raison sociale'
            type='text'
            fullWidth
            value={name}
            onChange={e => setName(e.target.value)}
          />
          <Typography variant='h6' mt={3}>Adresse</Typography>
          <Box sx={{ pl: 3, mb: 3 }}>
            <TextField
              margin='dense'
              id='street'
              label='Rue'
              type='text'
              fullWidth
              value={street || ''}
              onChange={e => setStreet(e.target.value)}
            />
            <TextField
              margin='dense'
              id='zipcode'
              label='Code postal'
              type='text'
              fullWidth
              value={zipcode || ''}
              onChange={e => setZipcode(e.target.value)}
            />
            <TextField
              margin='dense'
              id='city'
              label='Ville'
              type='text'
              fullWidth
              value={city || ''}
              onChange={e => setCity(e.target.value)}
            />
          </Box>
          <DatePicker
            disableToolbar
            variant='inline'
            format='dd-MM-yyyy'
            margin='dense'
            fullWidth
            id='_createdAtTime'
            label='Date de création'
            value={item._createdAtTime?.toDate() || null}
            disabled
            renderInput={params => <TextField {...params} />}
            onChange={() => null}
          />
          <TextField
            margin='dense'
            id='licenses'
            label='Nombre de licences'
            type='number'
            fullWidth
            value={licences || 0}
            onChange={e => setLicences(e.target.value)}
          />
          <TextField
            margin='dense'
            id='ext-licences'
            label='Nombre de licences externes'
            type='number'
            fullWidth
            value={externalLicences || 0}
            onChange={e => setExternalLicences(e.target.value)}
          />
          <TextField margin='dense' id='buyer' label='Acheteur' type='text' fullWidth value={item.buyer?.email} disabled />
          <TextField margin='dense' id='id' label='id' type='text' fullWidth value={item.id} disabled />
          <FormControl variant='outlined'>
            <Select id='consultant-select' label='Consultant' value={consultantId || ''} onChange={e => setConsultantId(e.target.value)} margin='dense'>
              <MenuItem value='none'><em>Pas de consultant</em></MenuItem>
              {consultants.map(consultant => <MenuItem key={consultant.id} value={consultant.id}>
                {`${consultant.company.name} - ${consultant.firstname}`}
              </MenuItem>)}
            </Select>
          </FormControl>
          <LogoInput companyId={item.id} />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color='primary'>Annuler</Button>
          <Button type='submit' color='primary'>Valider</Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

CompanyDetailsDialog.propTypes = {
  item: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default CompanyDetailsDialog
