import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage'
import { UPLOAD_COMPLETE, UPLOAD_ERROR, UPLOAD_PROGRESS } from '../types'


// https://firebase.google.com/docs/storage/web/start
export const uploadFileAction = (file, folder, fileName) => (dispatch, getState, { storage }) =>
  new Promise(resolve => {
    const storageRef = ref(storage, `${folder || 'misc'}/${fileName ? fileName + '.' +file.name.split('.').pop() : file.name}`)
    const uploadTask = uploadBytesResumable(storageRef, file)
    uploadTask.on('state_changed',
      snapshot => dispatch({ type: UPLOAD_PROGRESS, progress: 100 * snapshot.bytesTransferred / snapshot.totalBytes }),
      error => dispatch({ type: UPLOAD_ERROR, error }),
      () => dispatch({ type: UPLOAD_COMPLETE }),
    )
    uploadTask.then(snapshot => resolve(getDownloadURL(ref(storage, snapshot.ref.fullPath))))
  })
