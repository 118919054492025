export const TALENT = 'talent'
export const POSITION = 'position'
export const COMMUNICATION = 'communication'
export const MANAGEMENT = 'management'
export const ERROR = 'content-error'
export const TEAM_PROFILE = 'team-profile'
export const TALENT_EXT = 'talent-ext'
export const DEMO_TEAM = 'demo-team'
export const SHARE_YOUR_NEEDS ='share-your-needs'
export const PARACHUTE ='parachute'
export const WINNING_DUO ='duo-gagnant'
export const WORKING_TEAM ='equipe-qui-marche'
export const ONE_TO_ONE_INTRO = 'one-to-one-intro'
export const ONE_TO_ONE_WHY = 'one-to-one-why'
export const ONE_TO_ONE_HOW = 'one-to-one-how'
export const ONE_TO_ONE_ADAPT = 'one-to-one-adapt'
export const ONE_TO_ONE_BUILDER = 'one-to-one-builder'
export const GROUP_CONVERSATION = 'group-conversation'
