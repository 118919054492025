import React, { useEffect } from 'react'
import Logo from '../../assets/logo-boussole.svg'
import LoginForm from './LoginForm'
import { useDispatch } from 'react-redux'
import PhoneLoginForm from './PhoneLoginForm'
import { initCaptchaAction } from '../../store/actions/authActions'
import Page from '../_library/Page'
import { Box, Grid, Typography } from '@mui/material'


export const LoginPage = () => {
  
  const dispatch = useDispatch()
  
  useEffect(() => {
    dispatch(initCaptchaAction())
  }, [])
  
  return (
    <Page>
      <Grid
        container
        justifyContent='center'
        alignItems='center'
        style={{ height: '100vh' }}
      >
        <Grid item lg={4} md={8} xs={10} style={{ textAlign: 'center' }}>
          <Typography variant='h2'>Plateforme d&apos;administration</Typography>
          <Box component='img' src={Logo} sx={{ width: '500px', maxWidth: '70%', mb: '5vh' }} alt='Wake up Logo' />
          <LoginForm />
          <PhoneLoginForm />
        </Grid>
      </Grid>
    </Page>
  )
}

export default LoginPage
