import { LISTEN_COLLECTION } from '../types'

const firestoreReducer = (state = {}, action) => {
  switch(action.type) {

    case LISTEN_COLLECTION:
      return {
        ...state,
        [action.collection]: action.payload,
      }

    default:
      return state
  }
}

export default firestoreReducer
