import Pptxgenjs from 'pptxgenjs'
import logo from '../assets/logo-boussole.png'
import teamConversation from '../assets/SLIDES/teamConversation.png'
import salut from '../assets/SLIDES/salut.jpeg'
import logoSmall from '../assets/SLIDES/logo.png'
import avatarTeamMember from '../assets/SLIDES/noPictureAvatar.png'
import timer from '../assets/SLIDES/timer.gif'
import timer130 from '../assets/SLIDES/timer130.gif'
import timer500 from '../assets/SLIDES/timer500.gif'
import mysteriousAvatar from '../assets/SLIDES/mysteriousAvatar.jpeg'
import emptyAvatar from '../assets/SLIDES/emptyAvatar.jpeg'
import questionGame from '../assets/SLIDES/questionGame.jpeg'
import safeCocoonCover from '../assets/SLIDES/safeCocoonCover.png'
import bubble from '../assets/SLIDES/speachBubble.png'
import highFive from '../assets/SLIDES/highFive.png'
import arrow2 from '../assets/SLIDES/arrow2.png'
import postIt from '../assets/SLIDES/postIt.png'
import _, { forEach, mapValues } from 'lodash'
import { getTeamBadge, getTeamProfileType, getTeamTitle } from './cardHelper'
import { format } from 'date-fns'
import { fr } from 'date-fns/locale'
import { CONVERSATION_TYPES } from '../_constants/globals'


const palette = {
  primary: {
    main: '#3DADAD',
    light: '#3CADAC',
    dark: '#343051',
  },
  secondary: {
    main: '#F05',
  },
  tertiary: {
    main: '#d17838',
    light: '#D99362',
    dark: '#BE4F23',
    ultraLight: '#F9EDCF',
  },
  grey: {
    100: '#F8F0D7',
    150: '#EAEAEA',
    200: '#C8C8C8',
    250: '#B7B7B7',
    300: '#9e9bac',
    700: '#464646',
  },
  background: {
    default: '#F8F8F8',
    blue: '#3DADAD',
    deepBlue: '#343051',
  },
  divider: '#343051',
  action: {
    active: '#ddd',
  },
  error: {
    main: '#F05',
  },
}

/**
 * Powerpoint 1
 *
 * @param {string} author
 * @param {string} company
 * @param {object} team
 * @param {object[]} members
 * @param {object} sumUpCard
 */
export const getSafeCocoonPPTX = async (author, company, team, members, sumUpCard) => {
  const pptx = new Pptxgenjs()
  
  pptx.author = author
  pptx.company = `BOUSSOLE - ${company}`
  pptx.subject = team.name
  pptx.title = 'Safe cocoon'
  
  const shuffle = _.shuffle(members)
  const userGroups = _.chain(members).shuffle().groupBy(m => m.profileType).value()
  
  // Slide 1
  let slide = pptx.addSlide()
  slide.addImage({ path: logo, x: '7%', y: '20%', w: '30%', h: '15%' })
  slide.addText(
    "Synchronisez vos super-pouvoirs",
    { x: '7%', y: '40%', w: '36%', h: '50%', fontSize: 30, color: palette.primary.main, fontFace: 'Arial', bold: true },
  )
  slide.addImage({ path: safeCocoonCover, x: '50%', y: 0, w: '50%', h: '100%' })
  
  // Slide 2
  insertBonjourSlide(pptx.addSlide(), shuffle, 'premier')
  
  // Slide 3
  insertSlideTimer(pptx.addSlide())
  
  // Slide 4
  insertSlideTimeMaster(pptx.addSlide(), 'idée : choisissez la personne qui a les cheveux les plus courts.', shuffle, pptx)
  
  // Slide 5
  insertSlideRules(pptx.addSlide())
  
  // Slide 6
  slide = pptx.addSlide()
  insertLogo(slide)
  insertTimer(slide, 500)
  slide.addImage({ path: mysteriousAvatar, x: '41%', y: '17%', w: '18%', h: '31%' })
  slide.addText(
    "Tour de chauffe. Saurez-vous deviner :",
    { x: '15%', y: '50%', w: '70%', h: '10%', fontSize: 25, fontFace: 'Arial', bold: true, align: 'center' },
  )
  slide.addText(
    "Qui est qui ?",
    { x: '15%', y: '60%', w: '70%', h: '20%', fontSize: 50, color: palette.primary.main, fontFace: 'Arial', bold: true, align: 'center' },
  )
  
  // Slide 7-12 - Matching profiles
  forEach(userGroups, (members, profile) => {
    slide = pptx.addSlide()
    insertLogo(slide)
    insertAvatars(slide, members, { y: 17, diameter: 18, anonymous: true })
    slide.addText(
      members.length > 1 ? "Leur talent..." : "Le talent de...",
      { x: '15%', y: '50%', w: '70%', h: '10%', fontSize: 30, color: palette.primary.main, fontFace: 'Arial', bold: true, align: 'center' },
    )
    const gender = members.length > 1 ? 'plural' : members[0].gender
    slide.addText(
      sumUpCard.article[profile].fr[gender].replace('<p>', '').replace('</p>', '').replace(/FIRSTNAME/g, '...'),
      { x: '15%', y: '60%', w: '70%', h: '40%', fontSize: 15, fontFace: 'Arial', bold: true, align: 'center', valign: 'top' },
    )
    slide = pptx.addSlide()
    insertLogo(slide)
    insertAvatars(slide, members, { y: 17, diameter: 18, printFirstname: palette.primary.main })
    slide.addText(
      sumUpCard.article[profile].fr[gender].replace('<p>', '').replace('</p>', '').replace(/FIRSTNAME/g, members[0].firstname),
      { x: '15%', y: '60%', w: '70%', h: '40%', fontSize: 15, color: palette.grey[300], fontFace: 'Arial', bold: true, align: 'center', valign: 'top' },
    )
  })
  
  // Slide 13
  slide = pptx.addSlide()
  insertLogo(slide)
  slide.addImage({ path: questionGame, x: '41%', y: '17%', w: '22%', h: '31%' })
  slide.addText(
    "Maintenant, voici 4 questions pour vous.",
    { x: '5%', y: '50%', w: '90%', h: '10%', fontSize: 30, color: palette.primary.main, fontFace: 'Arial', align: 'center', bold: true },
  )
  slide.addText(
    "Prêts ? En voiture, Simone !",
    { x: '15%', y: '60%', w: '70%', h: '10%', fontSize: 30, color: palette.primary.main, fontFace: 'Arial', align: 'center', bold: true },
  )
  
  // Slide 14
  insertSlideGroupQuestion(
    pptx.addSlide(),
    "Quelle est la chose la plus surprenante que vous ayez apprise en lisant vos propres cartes ?",
    "Prenez 1 minute pour écrire votre réponse.",
    1,
    4,
    pptx,
    members,
  )
  
  // Slide 15
  insertSlideGroupQuestion(
    pptx.addSlide(),
    "Quelle est la chose à faire absolument pour bien travailler avec vous ?",
    "Prenez 1 minute pour écrire votre réponse.",
    2,
    4,
    pptx,
    members,
  )
  
  // Slide 16
  insertSlideGroupQuestion(
    pptx.addSlide(),
    "Quelle est la chose à ne surtout pas faire pour bien travailler avec vous ?",
    "Prenez 1 minute pour écrire votre réponse.",
    3,
    4,
    pptx,
    members,
  )
  
  // Slide 17
  insertSlideGroupQuestion(
    pptx.addSlide(),
    "Qu'attendez-vous de la part de votre équipe lorsque vous partagez une idée ?",
    "Prenez 1 minute pour écrire votre réponse.",
    4,
    4,
    pptx,
    members,
    'Puis répondez de gauche à droite',
  )
  
  // Slide 18
  insertSlideGroupQuestion(
    pptx.addSlide(),
    'Partagez un enseignement que vous avez tiré de cette conversation.',
    "Prenez 1 minute pour écrire votre réponse.",
    null,
    null,
    pptx,
    members,
  )
  
  // Slide 19
  slide = pptx.addSlide()
  insertLogo(slide)
  slide.addImage({ path: highFive, x: '44%', y: '19%', w: '15%', h: '25%' })
  slide.addText(
    "Hourra !",
    { x: '30%', y: '47%', w: '40%', h: '10%', fontSize: 30, color: palette.primary.main, fontFace: 'Arial', bold: true, align: 'center' },
  )
  slide.addText(
    "Vous avez fini avec brio ce premier Feu de Camp.",
    { x: '30%', y: '60%', w: '40%', h: '12%', fontSize: 20, fontFace: 'Arial', bold: true, align: 'center' },
  )
  
  // Slide 20
  slide = pptx.addSlide()
  slide.background = { color: palette.tertiary.main }
  slide.addText(
    "La suite :",
    { x: '12%', y: '16%', w: '50%', h: '7%', fontSize: 30, color: '#FFFFFF', fontFace: 'Arial', bold: true },
  )
  slide.addShape(pptx.ShapeType.line, { x: '12%', y: '30%', w: '55%', h: '1%', line: { color: palette.tertiary.dark, width: 3, dashType: 'dash' } })
  slide.addImage({ path: arrow2, x: '12%', y: '35%', w: '3%', h: '5%' })
  slide.addText(
    `Tête-à-tête entre ${author} et chaque coéquipier`,
    { x: '16%', y: '34%', w: '80%', h: '5%', fontSize: 18, fontFace: 'Arial', bold: true, color: palette.tertiary.ultraLight },
  )
  slide.addShape(pptx.ShapeType.line, { x: '12%', y: '45%', w: '55%', h: '1%', line: { color: palette.tertiary.dark, width: 3, dashType: 'dash' } })
  slide.addImage({ path: arrow2, x: '12%', y: '50.5%', w: '3%', h: '5%' })
  slide.addText(
    "Nouvelle conversation collective de 60 min pour continuer d’écrire vos règles d’équipe.",
    { x: '16%', y: '48%', w: '60%', h: '15%', fontSize: 18, fontFace: 'Arial', bold: true, color: palette.tertiary.ultraLight },
  )
  insertIdea(slide, pptx, `Date prévue le ${team[CONVERSATION_TYPES.TEAM_BUILDING] ? format(team[CONVERSATION_TYPES.TEAM_BUILDING].toDate(), 'dd MMMM yyyy', { locale: fr }) : '... ... ' + (new Date).getFullYear()}`, 15, 63, 30)
  slide.addShape(pptx.ShapeType.line, { x: '12%', y: '78%', w: '55%', h: '1%', line: { color: palette.tertiary.dark, width: 3, dashType: 'dash' } })
  
  await pptx.writeFile({ fileName: `feu-de-camp-1-${team.name}.pptx` })
}

/**
 * Powerpoint 2
 *
 * @param {string} author
 * @param {string} company
 * @param {string} teamName
 * @param {object[]} members
 * @param {object} sumUpCard
 * @param {string} teamId
 * @param {object} graphImage
 * @param {object} graphImageBg
 * @param {string} teamProfile
 */
export const getTeamBuildingPPTX = async (author, company, teamName, members, sumUpCard, teamId, graphImage, graphImageBg, teamProfile) => {
  const pptx = new Pptxgenjs()
  
  pptx.author = author
  pptx.company = `BOUSSOLE - ${company}`
  pptx.subject = teamName
  pptx.title = 'Conversation d\'équipe'
  
  const shuffle = _.shuffle(members)
  const teamProfileType = getTeamProfileType(members)
  const teamBadge = getTeamBadge(teamProfileType)
  const teamTitle = getTeamTitle(teamProfileType)
  const teamArticle = teamProfile['gslide-description'][teamProfileType].fr
    .replace('<p>', '').replace('</p>', '')
    .replace('<h3>', '').replace('</h3>', '')
    .replace('Le talent de votre équipe', getTeamTitle(teamProfileType))
  
  // Slide 1
  let slide = pptx.addSlide()
  slide.addImage({ path: logo, x: '7%', y: '20%', w: '30%', h: '15%' })
  slide.addText(
    "Travaillez de concert.",
    { x: '7%', y: '35%', w: '36%', h: '50%', fontSize: 35, color: palette.primary.main, fontFace: 'Arial', bold: true },
  )
  slide.addShape(pptx.ShapeType.rect, { x: '50%', y: 0, h: '100%', w: '50%', fill: { color: palette.primary.main } })
  slide.addImage({ path: teamConversation, x: '57%', y: '14%', w: '36%', h: '72%' })
  
  // Slide 2
  insertBonjourSlide(pptx.addSlide(), shuffle, 'deuxième')
  
  // Slide 3
  insertSlideTimer(pptx.addSlide())
  
  // Slide 4
  insertSlideTimeMaster(pptx.addSlide(), 'idée : choisissez la personne la plus jeune.', shuffle, pptx)
  
  // Slide 5
  insertSlideRules(pptx.addSlide())
  
  // Slide 6
  insertSlideGroupQuestion(
    pptx.addSlide(),
    "Quel est le mot qui décrit le mieux votre météo intérieure ?",
    'Prenez 1 minute pour écrire votre réponse.',
    1,
    5,
    pptx,
    members,
    null,
    '30s / pers.',
  )
  
  // Slide 7
  slide = pptx.addSlide()
  insertLogo(slide)
  slide.addText(
    "Si votre équipe était lâchée par hélicoptère pour une mission spéciale...",
    { x: '10%', y: '30%', w: '36%', h: '40%', fontSize: 30, color: palette.primary.main, fontFace: 'Arial', bold: true },
  )
  slide.addShape(pptx.ShapeType.rect, { x: '50%', y: 0, h: '100%', w: '50%', fill: { color: palette.tertiary.ultraLight } })
  slide.addImage({ data: graphImageBg, x: '52%', y: '9%', w: '46%', h: '81%' })
  
  // Slide 8
  slide = pptx.addSlide()
  insertLogo(slide)
  slide.addText(
    "…vous seriez les meilleurs à :",
    { x: '10%', y: '18%', w: '36%', h: '15%', fontSize: 25, color: palette.primary.main, fontFace: 'Arial', bold: true },
  )
  slide.addText(
    teamArticle,
    { x: '10%', y: '33%', w: '36%', h: '60%', fontSize: 16, fontFace: 'Arial' },
  )
  slide.addShape(pptx.ShapeType.rect, { x: '50%', y: 0, h: '100%', w: '50%', fill: { color: palette.tertiary.ultraLight } })
  slide.addImage({ path: teamBadge, x: '58%', y: '20%', w: '33%', h: '58%' })
  slide.addText(
    teamTitle,
    { x: '55%', y: '79%', w: '40%', h: '10%', fontSize: 25, fontFace: 'Arial', color: palette.grey[700], bold: true, align: 'center' },
  )
  
  // Slide 9
  slide = pptx.addSlide()
  insertLogo(slide)
  insertPagination(slide, 2, 5)
  slide.addText(
    "Quel est le challenge que seule votre équipe est en capacité de relever, et pourquoi ?",
    { x: '10%', y: '29%', w: '36%', h: '33%', fontSize: 25, color: palette.primary.main, fontFace: 'Arial', bold: true },
  )
  slide.addText(
    "Réponse tous ensemble !",
    { x: '10%', y: '65%', w: '36%', h: '5%', fontSize: 15, fontFace: 'Arial', bold: true },
  )
  slide.addShape(pptx.ShapeType.rect, { x: '50%', y: 0, h: '100%', w: '50%', fill: { color: palette.tertiary.ultraLight } })
  insertTimer(slide, 500, '8 min')
  slide.addImage({ path: teamBadge, x: '58%', y: '20%', w: '33%', h: '58%' })
  slide.addText(
    teamTitle,
    { x: '55%', y: '79%', w: '40%', h: '10%', fontSize: 25, fontFace: 'Arial', color: palette.grey[700], bold: true, align: 'center' },
  )
  
  // Slide 10
  slide = pptx.addSlide()
  insertLogo(slide)
  insertPagination(slide, 3, 5)
  insertTimer(slide, 130)
  slide.addText(
    "Choisissez une personne de l’équipe indispensable au bon déroulé de votre mission. Comment challenge-t-elle votre façon de voir le monde ?",
    { x: '5%', y: '18%', w: '49%', h: '38%', fontSize: 22, color: palette.primary.main, fontFace: 'Arial', bold: true },
  )
  insertRightArrowComponent(slide, pptx.shapes.LINE, { x: 5, y: 60 })
  insertAvatars(slide, _.shuffle(members), { y: 70, leftAlign: true, printFirstname: true })
  slide.addImage({ data: graphImage, x: '57%', y: '10%', w: '36%', h: '61%' })
  
  // Slide 11
  insertSlideGroupQuestion(
    pptx.addSlide(),
    "Quel est le comportement positif que vous voulez encourager le plus dans votre équipe ?",
    "Prenez 1 minute pour écrire votre réponse.",
    4,
    5,
    pptx,
    members,
    'Puis répondez de gauche à droite',
  )
  
  // Slide 12
  slide = pptx.addSlide()
  insertLogo(slide)
  insertPagination(slide, 4, 5)
  insertTimer(slide, 500)
  slide.addImage({ path: postIt, x: '44%', y: '19%', w: '15%', h: '25%' })
  slide.addText(
    'Si votre équipe ne devait en choisir qu’un seul, quel serait-il ?',
    { x: '10%', y: '46%', w: '80%', h: '20%', fontSize: 28, color: palette.primary.main, fontFace: 'Arial', bold: true, align: 'center' },
  )
  
  // Slide 13
  insertSlideGroupQuestion(
    pptx.addSlide(),
    "Citez une chose sur laquelle vous pourriez vous engager personnellement pour renforcer ce comportement.",
    "Prenez 1 minute pour écrire votre réponse.",
    5,
    5,
    pptx,
    members,
    null,
    '1 min',
  )
  
  // Slide 14
  insertSlideGroupQuestion(
    pptx.addSlide(),
    'Partagez un enseignement que vous avez tiré de cette conversation.',
    "Prenez 1 minute pour écrire votre réponse.",
    null,
    null,
    pptx,
    members,
  )
  
  // Slide 15
  slide = pptx.addSlide()
  insertLogo(slide)
  slide.addImage({ path: highFive, x: '44%', y: '19%', w: '15%', h: '25%' })
  slide.addText(
    "Merci d’avoir \njoué le jeu !",
    { x: '30%', y: '47%', w: '40%', h: '20%', fontSize: 30, color: palette.primary.main, fontFace: 'Arial', bold: true, align: 'center' },
  )
  slide.addText(
    "Vous avez fini avec brio ce deuxième Feu de Camp.",
    { x: '30%', y: '69%', w: '40%', h: '12%', fontSize: 20, fontFace: 'Arial', bold: true, align: 'center' },
  )
  
  await pptx.writeFile({ fileName: `feu-de-camp-2-${teamName}.pptx` })
}

const insertAvatars = (slide, members, options) => {
  const { y = 65, leftAlign, printFirstname, diameter = 6, anonymous, debug } = options
  const spacing = 1
  let margin = 50 - Math.min(members.length, 10) * diameter / 2 - (Math.min(members.length, 10) - 1) * spacing
  if (leftAlign) margin = 5
  _.chunk(members, 10).forEach((membersLine, line) =>
    membersLine.forEach((member, column) => {
      debug && console.log(member.firstname)
      debug && console.log('column', column)
      debug && console.log('diameter', diameter)
      const imagePos = {
        x: column * (diameter + 2 * spacing) + margin,
        y: y + line * (diameter * 16 / 9 * 1.5),
        w: diameter,
        h: diameter * 16 / 9,
      }
      debug && console.log('image y', imagePos.y)
      debug && console.log('image h', imagePos.h)
      slide.addImage({
        path: anonymous ? emptyAvatar : member.photoUrl || avatarTeamMember,
        ...mapValues(imagePos, pos => pos + '%'),
      })
      const textPos = {
        x: imagePos.x - spacing,
        y: imagePos.y + imagePos.h + diameter / 6 + 1,
        w: diameter + 2 * spacing,
        h: diameter / 7,
      }
      debug && console.log('text y', textPos.y)
      debug && console.log('text h', textPos.h)
      printFirstname && slide.addText(member.firstname, {
        fontFace: 'Arial',
        align: 'center',
        fontSize: 1.5 * diameter,
        color: typeof printFirstname === 'string' ? printFirstname : '000000',
        bold: typeof printFirstname === 'string',
        ...mapValues(textPos, pos => pos + '%'),
      })
    }),
  )
}

const insertRightArrowComponent = (slide, line, options) => {
  const { color = palette.tertiary.main, x = 40, y = 50, text = 'puis répondez de gauche à droite' } = options
  slide.addText(
    text || 'puis répondez de gauche à droite',
    { x: x + '%', y: y + '%', w: '30%', h: '5%', fontSize: 10, fontFace: 'Arial', bold: true },
  )
  slide.addShape(line, {
    x: `${x + 1}%`, y: `${y + 6}%`, w: '18%', h: 0,
    line: { color, width: 3, endArrowType: 'triangle' },
  })
}

const insertTimer = (slide, type, text) => {
  if (type <= 300) {
    slide.addImage({ path: timer130, x: '95%', y: '1.5%', w: '4%', h: '7%' })
    slide.addText(
      text || "1 min 30 / pers.",
      { x: '70%', y: '1.5%', w: '25%', h: '7%', fontSize: 10, fontFace: 'Arial', bold: true, align: 'right', valign: 'middle' },
    )
  } else {
    slide.addImage({ path: timer500, x: '95%', y: '1.5%', w: '4%', h: '7%' })
    slide.addText(
      text || "5 min",
      { x: '70%', y: '1.5%', w: '25%', h: '7%', fontSize: 10, fontFace: 'Arial', bold: true, align: 'right', valign: 'middle' },
    )
  }
}

const insertLogo = slide => {
  slide.addImage({ path: logoSmall, x: '1%', y: '1.5%', w: '4%', h: '7%' })
}

const insertPagination = (slide, page, total) => {
  slide.addText(page, { x: '6%', y: '2.5%', w: '3%', h: '6%', fontSize: 30, fontFace: 'Arial', bold: true, align: 'right', color: palette.tertiary.main })
  slide.addText('/' + total, { x: '9%', y: '4.5%', w: '5%', h: '4%', fontSize: 20, fontFace: 'Arial', bold: true, align: 'left', color: palette.grey[250] })
}

const insertIdea = (slide, pptx, text, x, y) => {
  const backgroundWidth = .9 * text.length + 7
  const backgroundX = x || (100 - backgroundWidth) / 2
  slide.addShape(pptx.ShapeType.rect, { x: `${backgroundX}%`, y: `${y}%`, h: '9%', w: `${backgroundWidth}%`, fill: { color: palette.tertiary.ultraLight } })
  slide.addImage({ path: bubble, x: `${backgroundX+1.5}%`, y: `${y+1.5}%`, w: '2.5%', h: '10%' })
  slide.addText(
    text,
    { x: `${backgroundX+4}%`, y: `${y}%`, h: '9%', w: `${backgroundWidth}%`, fontSize: 14, fontFace: 'Arial', italic: true, color: palette.grey[700], bold: true },
  )
}

const insertBonjourSlide = (slide, members, number) => {
  insertLogo(slide)
  slide.addImage({ path: salut, x: '36%', y: '7%', w: '26%', h: '33%' })
  slide.addText(
    "Bonjour !",
    { x: '15%', y: '40%', w: '70%', h: '10%', fontSize: 30, color: palette.primary.main, fontFace: 'Arial', bold: true, align: 'center' },
  )
  slide.addText(
    `Nous sommes ravis de vous retrouver tous ensemble pour ce ${number} Feu de Camp.`,
    { x: '15%', y: '50%', w: '70%', h: '10%', fontSize: 17, fontFace: 'Arial', bold: true, align: 'center' },
  )
  insertAvatars(slide, members, { printFirstname: true })
}

const insertSlideRules = slide => {
  insertLogo(slide)
  slide.addText(
    "Pour cette conversation, vous pouvez décider de :",
    { x: '20%', y: '25%', w: '60%', h: '20%', fontSize: 25, color: palette.primary.main, fontFace: 'Arial', bold: true, align: 'center' },
  )
  slide.addText(
    "1. Jouer en portant un masque",
    { x: '15%', y: '51%', w: '70%', h: '10%', fontSize: 20, fontFace: 'Arial', bold: true, align: 'center' },
  )
  slide.addText(
    "2. Jouer de manière sincère",
    { x: '15%', y: '58%', w: '70%', h: '10%', fontSize: 20, fontFace: 'Arial', bold: true, align: 'center' },
  )
  slide.addText(
    "C’est avec la seconde que votre équipe peut gagner.",
    { x: '15%', y: '70%', w: '70%', h: '10%', fontSize: 20, fontFace: 'Arial', align: 'center' },
  )
}

const insertSlideTimeMaster = (slide, idea, shuffle, pptx) => {
  insertLogo(slide)
  slide.addText(
    "Commencez par élire un maître du temps.",
    { x: '20%', y: '18%', w: '60%', h: '5%', fontSize: 30, fontFace: 'Arial', bold: true, align: 'center', color: palette.primary.main },
  )
  slide.addText(
    "Il s’assurera que chacun ait bien un temps de parole.",
    { x: '10%', y: '35%', w: '80%', h: '10%', fontSize: 20, fontFace: 'Arial', bold: true, align: 'center' },
  )
  insertIdea(slide, pptx, idea, null, 50)
  insertAvatars(slide, shuffle, { printFirstname: true })
}

const insertSlideTimer = slide => {
  insertLogo(slide)
  insertTimer(slide, '130')
  slide.addImage({ path: timer, x: '44%', y: '30%', w: '15%', h: '25%' })
  slide.addText(
    "Le timer en haut à droite sera utile au maître du temps. Il déterminera le temps maximum à consacrer à chaque question.",
    { x: '15%', y: '57%', w: '70%', h: '20%', fontSize: 15, fontFace: 'Arial', bold: true, align: 'center' },
  )
}

const insertSlideGroupQuestion = (slide, question, instructions, page, total, pptx, members, rightArrowTxt, timing) => {
  insertLogo(slide)
  page && insertPagination(slide, page, total)
  insertTimer(slide, 130, timing)
  slide.addText(
    question,
    { x: '10%', y: '19%', w: '80%', h: '15%', fontSize: 28, color: palette.primary.main, fontFace: 'Arial', bold: true, align: 'center' },
  )
  instructions && slide.addText(
    instructions,
    { x: '20%', y: '40%', w: '60%', h: '10%', fontSize: 18, fontFace: 'Arial', bold: true, align: 'center' },
  )
  insertRightArrowComponent(slide, pptx.shapes.LINE, { text: rightArrowTxt })
  insertAvatars(slide, _.shuffle(members), { printFirstname: true })
}
