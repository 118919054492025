import React, { useEffect, useState } from 'react'
import { Grid, Button, TextField } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { emailSignInAction } from '../../store/actions/authActions'
import { HOME } from '../../_constants/routes'
import { useNavigate } from 'react-router-dom'


const LoginForm = () => {
  
  const profile = useSelector(state => state.auth.profile)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [email, setEmail] = useState(null)
  const [password, setPassword] = useState(null)
  
  useEffect(() => {
    if (profile && !profile.isEmpty) navigate(HOME)
  }, [profile])

  const handleSubmit = e => {
    e.preventDefault()
    if (email && password)
      dispatch(emailSignInAction({ email, password }))
  }

  return (
    <form onSubmit={handleSubmit} id='loginForm' style={{ padding: 20 }}>
      <Grid container>
        <Grid item xs={12}>
          <TextField
            required
            onChange={e => setEmail(e.target.value)}
            name='email'
            type='email'
            value={email || ''}
            label='Email'
            sx={{ width: { xs: '80%', sm: '60%' }, borderRadius: '4px' }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            onChange={e => setPassword(e.target.value)}
            name='password'
            type='password'
            value={password || ''}
            label='Password'
            sx={{ width: { xs: '80%', sm: '60%' }, borderRadius: '4px' }}
          />
        </Grid>
        <Grid container direction='column' alignItems='center'>
          <Button type='submit' variant='contained' color='primary' sx={{ my: 1, mx: 0, width: 'fit-content' }}>
            Login
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}

export default LoginForm
