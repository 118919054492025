import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Grid, Button, TextField } from '@mui/material'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { phoneSignInAction } from '../../store/actions/authActions'
import { withStyles } from '@mui/styles'


const styles = theme => ({
  textField: {
    width: '60%',
    borderRadius: 4,
    [theme.breakpoints.down('xs')]: {
      width: '80%',
    },
  },
  button: {
    margin: theme.spacing(1, 0),
    width: 'fit-content',
  },
})

const PhoneLoginForm = ({ classes, signInAction, allowSignInWithPhoneNumber, confirmationResult }) => {

  const [phoneNumber, setPhoneNumber] = useState(null)
  const [code, setCode] = useState(null)

  const submitNumber = e => {
    console.log('submitNumber')
    e.preventDefault()
    if (phoneNumber) signInAction(phoneNumber)
  }

  const submitCode = e => {
    console.log('submitCode')
    e.preventDefault()
    if (code) confirmationResult.confirm(code).then(answer => console.log('answer', answer))
  }

  console.log('confirmationResult', confirmationResult)

  return (
    <form onSubmit={confirmationResult ? submitCode : submitNumber} id='phoneLoginForm' style={{ padding: 20 }}>
      <Grid container>
        <Grid item xs={12}>
          {confirmationResult
            ? <TextField
              required
              onChange={e => setCode(e.target.value)}
              name='code'
              type='code'
              value={code || ''}
              label='code'
              className={classes.textField}
            />
            : <TextField
              required
              onChange={e => setPhoneNumber(e.target.value)}
              name='phoneNumber'
              type='phoneNumber'
              value={phoneNumber || ''}
              label='Phone number'
              className={classes.textField}
            />
          }
        </Grid>
        <Grid container direction='column' alignItems='center'>
          <Button
            type='submit'
            variant='contained'
            color='primary'
            id='phone-sign-in-button'
            disabled={!allowSignInWithPhoneNumber}
            sx={{ my: 1, mx: 0, width: 'fit-content' }}
          >
            Login
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}

const mapStateToProps = state => ({
  allowSignInWithPhoneNumber: state.auth.allowSignInWithPhoneNumber,
  confirmationResult: state.auth.confirmationResult,
})

PhoneLoginForm.propTypes = {
  classes: PropTypes.object.isRequired,
  signInAction: PropTypes.func.isRequired,
  allowSignInWithPhoneNumber: PropTypes.bool,
  confirmationResult: PropTypes.object,
}

export default compose(
  connect(mapStateToProps, { signInAction: phoneSignInAction }),
  withStyles(styles),
)(PhoneLoginForm)
