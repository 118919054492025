import React from 'react'
import PropTypes from 'prop-types'
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material'
import { deleteDocAction } from '../../store/actions/firestoreActions'
import { useDispatch } from 'react-redux'


export const ModalDelete = ({ collection, id, callback }) => {
  
  const dispatch = useDispatch()

  const handleClose = () => callback(null)

  const saveEditAndHandleClose = () => dispatch(deleteDocAction(collection, id)).then(callback)

  return <Dialog open onClose={handleClose}>
    <DialogTitle>Delete confirm</DialogTitle>
    <DialogContent>Are you sure that you wanna delete : {collection} {id}</DialogContent>
    <DialogActions>
      <Button onClick={handleClose} color='secondary'>Cancel</Button>
      <Button onClick={saveEditAndHandleClose} color='secondary'>Confirm</Button>
    </DialogActions>
  </Dialog>
}

ModalDelete.propTypes = {
  collection: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  callback: PropTypes.func,
}

export default ModalDelete
