import { createTheme, responsiveFontSizes } from '@mui/material/styles'

const palette = {
  primary: {
    light: '#63ccff',
    main: '#009be5',
    dark: '#006db3',
  },
}

const theme = responsiveFontSizes(createTheme({
  palette,
  typography: {
    h1: {
      fontSize: '5rem',
    },
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
  },
  shape: {
    borderRadius: 8,
  },
  props: {
    MuiTab: {
      disableRipple: true,
    },
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
  components: {
    MuiListItem: {
      styleOverrides: {
        root: {
          cursor: 'pointer',
          display: 'block',
        },
      },
    },
  },
}))

export default theme
