import { CAPTCHA_VERIFIED, CONFIRMATION_RESULT, LISTEN_PROFILE } from '../types'

const INITIAL_STATE = {
  profile: { isLoaded: false },
}

// eslint-disable-next-line default-param-last
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LISTEN_PROFILE:
      return { ...state, profile: action.payload }
    case CAPTCHA_VERIFIED:
      return { ...state, allowSignInWithPhoneNumber: true }
    case CONFIRMATION_RESULT:
      return { ...state, confirmationResult: action.payload }
    default:
      return state
  }
}
