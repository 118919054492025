import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import dropPhoto from '../../assets/nologo.png'
import miniloader from '../../assets/miniloader.gif'
import { Box, Divider } from '@mui/material'
import { updateDocAction } from '../../store/actions/firestoreActions'
import { COMPANY_COLLECTION, LOGO_STORAGE_REF } from '../../_constants/globals'
import { uploadFileAction } from '../../store/actions/storageActions'


const imgStyle = {
  width: 50,
  verticalAlign: 'middle',
}

const LogoInput = ({ className, companyId }) => {

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const logoUrl = useSelector(state => state.firestore.companies?.find(c => c.id === companyId)?.logoUrl)

  const [uploading, setUploading] = useState(false)

  useEffect(() => { setUploading(false) }, [logoUrl])

  const handleChange = e => {
    setUploading(true)
    dispatch(uploadFileAction(e.target.files[0], LOGO_STORAGE_REF, `${companyId}-${(new Date()).getMilliseconds()}`))
  }

  return (
    <Box sx={{ mt: 1 }} className={className}>
      {uploading
        ? <Box component='img' sx={imgStyle} src={miniloader} alt='miniloader' style={{ borderRadius: '50%' }} />
        : logoUrl
          ? <Box component='img' sx={imgStyle} src={logoUrl} alt='logo' />
          : <Box component='img' sx={imgStyle} src={dropPhoto} alt='drop' />
      }
      <Box component='label' htmlFor='file'  id='editImageLabel' sx={{
        p: 1,
        color: 'grey.500',
        cursor: 'pointer',
        '&:hover': {
          textDecoration: 'underline',
        },
      }}>{t('upload')}</Box>
      {logoUrl && <>
        <Divider orientation='vertical' sx={{ display: 'inline', borderColor: 'grey.500' }} />
        <Box
          component='label'
          onClick={() => dispatch(updateDocAction(COMPANY_COLLECTION, companyId, { logoUrl: null }))}
          sx={{
            p: 1,
            color: 'grey.500',
            cursor: 'pointer',
            '&:hover': {
              textDecoration: 'underline',
            },
          }}
        >{t('remove')}</Box>
      </>}
      <Box
        component='input'
        type='file'
        name='file'
        id='file'
        onChange={handleChange}
        sx={{
          width: '0.1px',
          height: '0.1px',
          opacity: 0,
          overflow: 'hidden',
          position: 'absolute',
          zIndex: '-1',
        }}
      />
    </Box>
  )
}

LogoInput.propTypes = {
  className: PropTypes.string,
  companyId: PropTypes.string.isRequired,
}

export default LogoInput
