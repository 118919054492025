import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Typography, TextField, Button, Box } from '@mui/material'
import { fetchCollection, getDocAction } from '../../store/actions/firestoreActions'
import Answers from './Answers'
import { ANSWER_COLLECTION, USER_COLLECTION } from '../../_constants/globals'


export const UserFinder = () => {
  
  const dispatch = useDispatch()
  
  const [email, setEmail] = useState(null)
  const [uid, setUid] = useState(null)
  const [error, setError] = useState(null)
  const [user, setUser] = useState(null)
  const [answers, setAnswers] = useState(null)

  const checkUser = async profile => {
    setUser(profile)
    setUid(profile.id)
    setEmail(profile.email)
    console.log(profile)
    const answers = await dispatch(fetchCollection({
      collection: ANSWER_COLLECTION,
      where: [['userRef', '==', USER_COLLECTION, profile.id]] },
    ))
    setAnswers(answers)
  }

  const submitMail = async () => {
    setError(null)
    const users = await dispatch(fetchCollection({ collection: USER_COLLECTION, where: [['email', '==', email]] }))
    console.log('users', users)
    if (users.length === 0)
      return setError('User not found')
    const profile = users[0]
    await checkUser(profile)
  }

  const submitUid = async () => {
    setError(null)
    const snapshot = await dispatch(getDocAction(USER_COLLECTION, uid))
    if (!snapshot.exists)
      return setError('User not found')
    await checkUser(snapshot)
  }

  return (
    <Box sx={{ ml: '10%', mt: 4 }}>
      <Typography variant='h2'>Search user for details:</Typography>
      <div>
        {error && <Typography color='red'>Error: {error}</Typography>}
        <div style={{ display: 'flex' }}>
          <div>
            <TextField
              id='uid'
              label='Uid'
              value={uid || ''}
              onChange={event => setUid(event.target.value)}
            />
            <Button variant='contained' color='primary' onClick={submitUid} sx={{ display: 'block', mt: 1 }}>
              Search by uid...
            </Button>
          </div>
          <Box sx={{ pl: 1 }}>
            <TextField
              id='email'
              label='Email'
              value={email || ''}
              onChange={event => setEmail(event.target.value)}
            />
            <Button variant='contained' color='primary' onClick={submitMail} sx={{ display: 'block', mt: 1 }}>
              Search by email...
            </Button>
          </Box>
        </div>
        {user && <Typography>User found ! Watch console to see current profile</Typography>}
        {answers && <Answers answers={answers} />}
      </div>
    </Box>
  )
}

export default UserFinder
