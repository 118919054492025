import { RESET_NOTIFICATIONS, DELETE_NOTIFICATION, AUTH_ERROR } from '../types'

// eslint-disable-next-line default-param-last
export default (state = {}, action) => {
  switch (action.type) {
    case RESET_NOTIFICATIONS:
      return {}
    case DELETE_NOTIFICATION:
      return { ...state, [action.payload]: null }

    // AUTH
    case AUTH_ERROR:
      if (action.payload.code === 'auth/user-not-found')
        return { ...state, error: { severity: 'error', message: 'auth.user-fail' } }
      else if (action.payload.code === 'auth/wrong-password')
        return { ...state, error: { severity: 'error', message: 'auth.password-fail' } }
      else {
        console.error(action.payload.code, action.payload.message)
        return { ...state, error: { severity: 'error', message: action.payload.message } }
      }

    default:
      return state
  }
}
