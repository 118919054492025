/* eslint-disable react/display-name */
import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { compose } from 'recompose'
import { withStyles } from '@mui/styles'
import MUIDataTable from 'mui-datatables'
import Loading from '../_library/Loading'
import { format } from 'date-fns'
import EditUserDialog from '../data/EditUserDialog'
import { EMAIL_COLLECTION } from '../../_constants/globals'
import { omit } from 'lodash'
import { listenCollection } from '../../store/actions/firestoreActions'
import useListener from '../../hooks/useListener'


const styles = theme => ({
  root: {
    margin: '20px auto',
    maxWidth: 1200,
  },
  circularProgressContainer: {
    marginTop: 30,
  },
  tableRow: {
    borderBottom: `1px solid ${theme.palette.text.primary}`,
  },
  clickable: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
})

const EmailTable = ({ classes }) => {
  
  useListener(() => listenCollection({
    collection: EMAIL_COLLECTION,
    orderBy: [['_createdAtTime', 'desc']],
    limit: 1000,
  }))
  const emails = useSelector(state => state.firestore.emails)

  const [selectedEmailIndex, selectEmailIndex] = useState(null)

  const selectedEmail = useMemo(() => emails && selectedEmailIndex && emails[selectedEmailIndex], [emails, selectedEmailIndex])

  const data = useMemo(() =>
    emails?.map(({ id, _origin, _createdAtTime, options, response, to, type }) =>
      [id, _origin, _createdAtTime, JSON.stringify(omit(options, ['inviter', 'teammember'])), response?.success ? 'oui' : 'non', JSON.stringify(to), type],
    ), [emails])

  if (emails === undefined) return <Loading />
  else return (
    <div className={classes.root}>
      <MUIDataTable
        title='Emails List'
        data={data}
        columns={[
          { name: 'ID', options: { filter: false, sort: false, display: 'false' } },
          { name: 'Environnement', options: { display: 'false' } },
          { name: 'CreatedAt', options: { filter: false, sort: true, customBodyRender: date => date && format(date.toDate(), 'dd-MM-yyyy') } },
          { name: 'Options', options: { filter: false, sort: false, display: 'false' } },
          { name: 'Sent' },
          { name: 'Destinataire' },
          { name: 'Type',
            options: {
              filterType: 'multiselect',
            },
          },
        ]}
        options={{
          filterType: 'textField',
          selectableRows: 'none',
          responsive: 'simple',
          onRowClick: row => selectEmailIndex(row[0]),
          rowHover: false,
          setRowProps: () => ({
            className: classes.clickable,
          }),
        }}
      />
      {selectedEmail && <EditUserDialog user={selectedEmail} onClose={() => selectEmailIndex(null)} />}
    </div>
  )
}
EmailTable.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default compose(
  withStyles(styles),
)(EmailTable)
