import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField } from '@mui/material'
import { addDocAction, updateDocAction } from '../../store/actions/firestoreActions'
import { CAMPFIRE_TEMPLATE_COLLECTION } from '../../_constants/globals'
import { pickBy } from 'lodash'
import { uploadFileAction } from '../../store/actions/storageActions'
import VisuallyHiddenInput from '../_library/VisuallyHiddenInput'
import { CloudUpload } from '@mui/icons-material'


export const ModalEditCampfire = ({ template, setTemplate }) => {
  
  const dispatch = useDispatch()
  
  const [slug, setSlug] = useState('')
  const [title, setTitle] = useState('')
  const [order, setOrder] = useState(0)
  const [cardFile, setCardFile] = useState('')
  const [spreadsheetFile, setSpreadsheetFile] = useState('')
  const [debriefFile, setDebriefFile] = useState('')
  
  const handleClose = () => {
    setTemplate(null)
  }
  
  const handleSubmit = async e => {
    e.preventDefault()
    const object = {
      order: Number(order),
      slug,
      title: {
        fr: title,
      },
    }
    if (cardFile instanceof File)
      object.cardUrl = await dispatch(uploadFileAction(cardFile, 'campfireCards', `${slug}-${(new Date()).getMilliseconds()}`))
    if (spreadsheetFile instanceof File)
      object.spreadsheetUrl = await dispatch(uploadFileAction(spreadsheetFile, 'campfireCards', `${slug}-${(new Date()).getMilliseconds()}`))
    if (debriefFile instanceof File)
      object.debriefUrl = await dispatch(uploadFileAction(debriefFile, 'campfireCards', `${slug}-${(new Date()).getMilliseconds()}`))
    return template.id
      ? dispatch(updateDocAction(CAMPFIRE_TEMPLATE_COLLECTION, template?.id, pickBy(object))).then(handleClose)
      : dispatch(addDocAction(CAMPFIRE_TEMPLATE_COLLECTION, object)).then(handleClose)
  }
  
  useEffect(() => {
    if (template.id) {
      setTitle(template.title.fr)
      setSlug(template.slug)
      setOrder(template.order)
      setCardFile(template.cardUrl)
      setSpreadsheetFile(template.spreadsheetUrl)
      setDebriefFile(template.debriefUrl)
    }
  }, [template])
  
  return <Dialog open onClose={handleClose}>
    <form onSubmit={handleSubmit}>
      <DialogTitle>{template ? `Edit type ${template.id}` : 'Create type'}</DialogTitle>
      <DialogContent>
        {!template.id && <TextField
          label='Id'
          value={slug}
          onChange={event => setSlug(event.target.value)}
          sx={{ display: 'block', m: 2 }}
        />}
        <TextField
          label='Order'
          type='number'
          value={order}
          onChange={event => setOrder(event.target.value)}
          sx={{ display: 'block', m: 2 }}
        />
        <TextField
          label='Title'
          value={title}
          onChange={event => setTitle(event.target.value)}
          sx={{ display: 'block', m: 2 }}
        />
        <Button
          component='label'
          role={undefined}
          variant='contained'
          tabIndex={-1}
          startIcon={<CloudUpload />}
          onChange={e => setCardFile(e.target.files[0])}
        >
          Upload file
          <VisuallyHiddenInput type='file' />
        </Button>
        <Button
          component='label'
          role={undefined}
          variant='contained'
          tabIndex={-1}
          startIcon={<CloudUpload />}
          onChange={e => setSpreadsheetFile(e.target.files[0])}
        >
          Upload spreadsheet
          <VisuallyHiddenInput type='file' />
        </Button>
        <Button
          component='label'
          role={undefined}
          variant='contained'
          tabIndex={-1}
          startIcon={<CloudUpload />}
          onChange={e => setDebriefFile(e.target.files[0])}
        >
          Upload debrief
          <VisuallyHiddenInput type='file' />
        </Button>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color='primary'>Cancel</Button>
        <Button type='submit' color='secondary'>Save</Button>
      </DialogActions>
    </form>
  </Dialog>
}

ModalEditCampfire.propTypes = {
  template: PropTypes.object,
  setTemplate: PropTypes.func.isRequired,
}

export default ModalEditCampfire
