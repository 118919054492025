import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Button, Card, CardActions, CardContent, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid,
  MenuItem, Select, Typography, Checkbox, FormControlLabel, TextField, Box,
} from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import DeleteButton from '../_library/DeleteButton'
import InlineEditor from '../_library/InlineEditor'
import Avatar from '../_library/Avatar'
import { getRoles } from '../../_helpers/helpers'
import Loading from '../_library/Loading'
import { ArrowDropDown, ArrowDropUp, Star as StarIcon } from '@mui/icons-material'
import { isValid } from 'date-fns'
import { StyledRating } from '../_library/StyledRating'
import { TALENT_EXT } from '../../_constants/cardTypes'
import { deleteDocAction, listenCollection, updateDocAction } from '../../store/actions/firestoreActions'
import { CARD_COLLECTION, COMPANY_COLLECTION, ROLE_CONSULTANT, USER_COLLECTION } from '../../_constants/globals'
import { DatePicker } from '@mui/x-date-pickers'
import PersonalityGraph from './PersonalityGraph'
import useListener from '../../hooks/useListener'


const AVATAR_SIZE = 200

const EditUserDialog = ({ user, onClose }) => {
  
  const dispatch = useDispatch()
  const companies = useSelector(state => state.firestore.companies)
  const talentExtCard = useSelector(state => state.firestore.cards?.find(c => c.type === TALENT_EXT && c.userRef.id === user.id))
  useListener(() => listenCollection({
    collection: CARD_COLLECTION,
    where: [['type', '==', TALENT_EXT], ['userRef', '==', USER_COLLECTION, user.id]],
  }), [])

  const [expand, setExpand] = useState(false)

  const updateUserAction = (id, data) => dispatch(updateDocAction(USER_COLLECTION, id, data))

  if (!user) return <Loading />
  else return (
    <Dialog open onClose={onClose} aria-labelledby='form-dialog-title'>
      <DialogTitle id='form-dialog-title'>
        {user.firstname}&nbsp;<Typography component='span' fontSize='.9rem' color='textSecondary'>{user.id}</Typography>
      </DialogTitle>
      <Avatar photoUrl={user.photoUrl} sx={{ height: AVATAR_SIZE, width: AVATAR_SIZE, borderRadius: AVATAR_SIZE / 2, m: 'auto' }} />
      <DialogContent>
        <Typography variant='body2' color='textSecondary' gutterBottom mb={1}>Identité</Typography>
        <Card variant='outlined' sx={{ minWidth: '275px', mb: 4 }}>
          <CardContent>
            <Grid item xs={12}>
              <InlineEditor title='Prénom' value={user.firstname} handleSubmit={val => updateUserAction(user.id, { firstname: val })} />
            </Grid>
            <Grid item xs={12}>
              <InlineEditor title='Email' value={user.email} handleSubmit={val => updateUserAction(user.id, { email: val })} />
            </Grid>
            <Grid item xs={12}>
              <Typography color='textSecondary' display='inline-block' lineHeight='20px' width='200px'>Genre</Typography>
              <FormControl variant='outlined'>
                <Select
                  id='sex-select'
                  value={user.gender || 'woman'}
                  onChange={e => updateUserAction(user.id, { gender: e.target.value })}
                  inputProps={{ sx: { p: 1 } }}
                >
                  <MenuItem value='woman'>Femme</MenuItem>
                  <MenuItem value='man'>Homme</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Typography color='textSecondary' display='inline-block' lineHeight='20px' width='200px'>Date de création</Typography>
              <DatePicker
                autoOk
                format='dd-MM-yyyy'
                id='_createdAtTime'
                value={user._createdAtTime ? user._createdAtTime.toDate() : new Date()}
                onChange={val => isValid(val) && updateUserAction(user.id, { _createdAtTime: val })}
                renderInput={params => <TextField {...params} />}
                inputProps={{ sx: { p: 1 } }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox
                  checked={user.roles?.includes(ROLE_CONSULTANT) || false}
                  onChange={e => updateUserAction(user.id, { roles: e.target.checked ? [ROLE_CONSULTANT] : [] })}
                  disabled={!user.company}
                />}
                label='Consultant'
              />
            </Grid>
            {user.testResults && <Collapse in={expand} style={{ width: '100%' }}>
              <Grid item xs={12}><h3>Résultats du test</h3></Grid>
              <Box sx={{ overflowX: 'scroll' }}>
                <Box component='table' sx={{
                  '& tr': {
                    whiteSpace: 'nowrap',
                  },
                  '& td': {
                    display: 'inline-table',
                    minWidth: '140px',
                    px: 1,
                  },
                  '& td:nth-of-type(2)': {
                    textAlign: 'right',
                  },
                }}>
                  <tbody>
                    <tr style={{ backgroundColor: '#B6D7A8' }}>
                      <td>Analyser</td>
                      <td>{user.testResults.A.toFixed(0)}</td>
                    </tr>
                    <tr style={{ backgroundColor: '#FFE599' }}>
                      <td>Créer</td>
                      <td>{user.testResults.C.toFixed(0)}</td>
                    </tr>
                    <tr style={{ backgroundColor: '#6D9EEB' }}>
                      <td>Motiver</td>
                      <td>{user.testResults.M.toFixed(0)}</td>
                    </tr>
                    <tr style={{ backgroundColor: '#EA9999' }}>
                      <td>Faire</td>
                      <td>{user.testResults.F.toFixed(0)}</td>
                    </tr>
                    <tr style={{ backgroundColor: '#CC0000' }}>
                      <td>X</td>
                      <td>{user.testResults.X.toFixed(1)}</td>
                    </tr>
                    <tr style={{ backgroundColor: '#3C78D8' }}>
                      <td>Y</td>
                      <td>{user.testResults.Y.toFixed(1)}</td>
                    </tr>
                    <tr>
                      <td>Profil</td>
                      <td>{user.profileType}</td>
                    </tr>
                    <tr>
                      <td>Ouverture d&apos;esprit</td>
                      <td>{user.testResults.OE.toFixed(0)}%</td>
                    </tr>
                    <tr>
                      <td>Introverti</td>
                      <td>{user.testResults.introverti.toFixed(1)}%</td>
                    </tr>
                    <tr>
                      <td>Extraverti</td>
                      <td>{user.testResults.extraverti.toFixed(1)}%</td>
                    </tr>
                    <tr style={{ backgroundColor: '#B6D7A8' }}>
                      <td>A1</td>
                      <td>{user.testResults.A1.toFixed(0)}</td>
                    </tr>
                    <tr style={{ backgroundColor: '#B6D7A8' }}>
                      <td>A2</td>
                      <td>{user.testResults.A2.toFixed(0)}</td>
                    </tr>
                    <tr style={{ backgroundColor: '#B6D7A8' }}>
                      <td>A3</td>
                      <td>{user.testResults.A3.toFixed(0)}</td>
                    </tr>
                    <tr style={{ backgroundColor: '#B6D7A8' }}>
                      <td>A4</td>
                      <td>{user.testResults.A4.toFixed(0)}</td>
                    </tr>
                    <tr style={{ backgroundColor: '#FFE599' }}>
                      <td>C1</td>
                      <td>{user.testResults.C1.toFixed(0)}</td>
                    </tr>
                    <tr style={{ backgroundColor: '#FFE599' }}>
                      <td>C2</td>
                      <td>{user.testResults.C2.toFixed(0)}</td>
                    </tr>
                    <tr style={{ backgroundColor: '#FFE599' }}>
                      <td>C3</td>
                      <td>{user.testResults.C3.toFixed(0)}</td>
                    </tr>
                    <tr style={{ backgroundColor: '#FFE599' }}>
                      <td>C4</td>
                      <td>{user.testResults.C4.toFixed(0)}</td>
                    </tr>
                    <tr style={{ backgroundColor: '#6D9EEB' }}>
                      <td>M1</td>
                      <td>{user.testResults.M1.toFixed(0)}</td>
                    </tr>
                    <tr style={{ backgroundColor: '#6D9EEB' }}>
                      <td>M2</td>
                      <td>{user.testResults.M2.toFixed(0)}</td>
                    </tr>
                    <tr style={{ backgroundColor: '#6D9EEB' }}>
                      <td>M3</td>
                      <td>{user.testResults.M3.toFixed(0)}</td>
                    </tr>
                    <tr style={{ backgroundColor: '#6D9EEB' }}>
                      <td>M4</td>
                      <td>{user.testResults.M4.toFixed(0)}</td>
                    </tr>
                    <tr style={{ backgroundColor: '#EA9999' }}>
                      <td>F1</td>
                      <td>{user.testResults.F1.toFixed(0)}</td>
                    </tr>
                    <tr style={{ backgroundColor: '#EA9999' }}>
                      <td>F2</td>
                      <td>{user.testResults.F2.toFixed(0)}</td>
                    </tr>
                    <tr style={{ backgroundColor: '#EA9999' }}>
                      <td>F3</td>
                      <td>{user.testResults.F3.toFixed(0)}</td>
                    </tr>
                    <tr style={{ backgroundColor: '#EA9999' }}>
                      <td>F4</td>
                      <td>{user.testResults.F4.toFixed(0)}</td>
                    </tr>
                  </tbody>
                </Box>
              </Box>
            </Collapse>}
          </CardContent>
          {user.testResults && <CardActions sx={{ p: 0, m: '0 -10px' }}>
            <Button color='primary' onClick={() => setExpand(prev => !prev)} sx={{
              width: '100%',
              bgcolor: 'grey.100',
              borderRadius: 0,
              height: '20px',
            }}>
              {expand
                ? <ArrowDropUp />
                : <ArrowDropDown />
              }
            </Button>
          </CardActions>}
        </Card>

        <Typography variant='body2' color='textSecondary' gutterBottom mb={1}>Entreprise</Typography>
        <Card variant='outlined' sx={{ minWidth: '275px', mb: 4 }}>
          <CardContent>
            <Grid item xs={12}>
              <Typography color='textSecondary' display='inline-block' lineHeight='20px' width='200px'>Client</Typography>
              <FormControl variant='outlined'>
                <Select
                  id='company-select'
                  value={user.companyRef?.id || ''}
                  onChange={e => updateUserAction(user.id, { companyRef: [COMPANY_COLLECTION, e.target.value] })}
                  inputProps={{ sx: { p: 1 } }}
                >
                  <MenuItem value={null}>&nbsp;</MenuItem>
                  {companies && Object.values(companies).map(company => <MenuItem key={company.id} value={company.id}>{company.name}</MenuItem>)}
                </Select>
              </FormControl>
            </Grid>
            {user.companyRef && <Grid item xs={12}>
              <Typography color='textSecondary' display='inline-block' lineHeight='20px' width='200px'>Role</Typography>
              <FormControl variant='outlined'>
                <Select
                  id='role-select'
                  value={user.accessLevel || 0}
                  onChange={e => updateUserAction(user.id, { accessLevel: e.target.value })}
                  inputProps={{ sx: { p: 1 } }}
                >
                  {getRoles().map(role => <MenuItem key={role.accessLevel} value={role.accessLevel}>{role.title}</MenuItem>)}
                </Select>
              </FormControl>
            </Grid>
            }
          </CardContent>
        </Card>

        {talentExtCard && <>
          <Typography variant='body2' color='textSecondary' gutterBottom mb={1}>Carte talent</Typography>
          <Card variant='outlined' sx={{ minWidth: '275px', mb: 4 }}>
            <CardContent>
              <Grid item xs={12}>
                <Typography color='textSecondary' display='inline-block' lineHeight='20px' width='200px'>Rating</Typography>
                <StyledRating
                  name='customized-color'
                  value={talentExtCard.rating}
                  readOnly
                  size='large'
                  icon={<StarIcon fontSize='inherit' />}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography color='textSecondary' display='inline-block' lineHeight='20px' width='200px'>Commentaire</Typography>
                <Typography display='inline-block'>{talentExtCard?.comment}</Typography>
              </Grid>
              <Grid item xs={12}>
                <PersonalityGraph users={[user]} displayHeart displayWeb />
              </Grid>
            </CardContent>
          </Card>
        </>}
      </DialogContent>
      <DialogActions>
        <DeleteButton deleteAction={() => dispatch(deleteDocAction(USER_COLLECTION, user.id)).then(onClose)} text='Supprimer' />
        <Button onClick={onClose} color='primary'>Fermer</Button>
      </DialogActions>
    </Dialog>
  )
}

EditUserDialog.propTypes = {
  user: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default EditUserDialog
