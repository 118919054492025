import React, { useEffect, useState } from 'react'
import { Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material'
import { getStats } from '../../store/actions/firestoreActions'
import { useDispatch } from 'react-redux'

const Stats = () => {
  
  const dispatch = useDispatch()
  
  const [numberUsers, setNumberUsers] = useState(0)
  const [numberUsersPremium, setNumberUsersPremium] = useState(0)
  const [numberUsersInvited, setNumberUsersInvited] = useState(0)
  const [desertionRate, setDesertionRate] = useState("")

  useEffect( () => {
    async function loadStats() {
      const { numberTestFinished, numberPremium, numberInvited, numberReadyForTest } = await dispatch(getStats())
      const desertionRate = ((1 - numberTestFinished / numberReadyForTest) * 100).toFixed(2)
      setNumberUsers(numberTestFinished)
      setNumberUsersPremium(numberPremium)
      setNumberUsersInvited(numberInvited)
      setDesertionRate(desertionRate)
    }
    loadStats()
  }, [])

  return (
    <div>
      <Typography variant='h2'>Stats of Boussole Pro:</Typography>
      <TableContainer component={Paper} style={{ maxWidth: '70%' }}>
        <Table aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold' }}>Name</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }} align='right'>Number</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow key='value'>
              <TableCell component='th' scope='row'>
                Number of users
              </TableCell>
              <TableCell align='right'>{numberUsers}</TableCell>
            </TableRow>
          </TableBody>
          <TableBody>
            <TableRow key='value'>
              <TableCell component='th' scope='row'>
                Number of premium users
              </TableCell>
              <TableCell align='right'>{numberUsersPremium}</TableCell>
            </TableRow>
          </TableBody>
          <TableBody>
            <TableRow key='value'>
              <TableCell component='th' scope='row'>
                Number of invited users
              </TableCell>
              <TableCell align='right'>{numberUsersInvited}</TableCell>
            </TableRow>
          </TableBody>
          <TableBody>
            <TableRow key='value'>
              <TableCell component='th' scope='row'>
                Desertion rate
              </TableCell>
              <TableCell align='right'>{desertionRate}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default Stats
