import i18n from '../i18n'
import * as qs from 'querystring'
import * as _ from 'lodash'

export const getCurrentLng = () => i18n.language || window.localStorage.i18nextLng || ''

export const xssCheck = string =>
  string && !['&', '<', '>', '"', "'", "/", '\\', '{', '}', '(', ')'].some(element => string.includes(element))

/**
 * Parses a string of type url params into an object
 *
 * @param urlParams
 *
 * @example
 * input : ?mode=resetPassword&oobCode=Ae5ee&apiKey=AIzaSyC5U&lang=en
 * output : {
 *   apiKey: "AIzaSy",
 *   lang: "en",
 *   mode: "resetPassword",
 *   oobCode: "Ae5ee"
 * }
 */
export const parseParams = urlParams => qs.parse(urlParams.replace('?', ''))

export const getRoles = () => [
  { title: 'Collaborateur', accessLevel: 25 },
  { title: 'Manager', accessLevel: 50 },
  { title: 'Admin', accessLevel: 75 },
  { title: 'SuperAdmin', accessLevel: 100 },
]

export const getTeamProfileType = teamMembers => {
  if (!teamMembers || !teamMembers[0]) return
  const result = teamMembers
    .reduce(
      (acc, val) => ({ testResults: { X: acc.testResults.X + val.testResults.X, Y: acc.testResults.Y + val.testResults.Y } } ),
      { testResults: { X: 0, Y: 0 } },
    )
  const X = result.testResults.X / teamMembers.length
  const Y = result.testResults.Y / teamMembers.length
  const absX = Math.abs(X)
  const absY = Math.abs(Y)
  let first, second
  if (X > 0) {
    if (Y > 0) {
      first = 'C'
      second = absX > absY ? 'M' : 'A'
    } else {
      first = 'M'
      second = absX > absY ? 'C' : 'F'
    }
  } else {
    if (Y > 0) {
      first = 'A'
      second = absX > absY ? 'F' : 'C'
    } else {
      first = 'F'
      second = absX > absY ? 'A' : 'M'
    }
  }
  const third = _.filter(teamMembers, el => el.profileType.split('')[2] === 'I').length > teamMembers.length / 2 ? 'I' : 'E'
  return first + second + third
}

export const getTeamCSVFile = (team, users, teamProfile) => {
  const csvRows = ['teamProfile, teamName, userId, urlAvatar, firstname, userProfile, gender, clientName, talentX, talentY, eScore']
  users.forEach(u => csvRows.push([teamProfile, team.name, u.id, u.photoUrl, u.firstname, u.profileType, u.gender, u.company.name, u.testResults?.X, u.testResults?.Y, u.testResults?.extraverti].join(', ')))
  console.log(csvRows)
  return csvRows.join('\n')
}
