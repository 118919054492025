import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField } from '@mui/material'
import { addDocAction, updateDocAction } from '../../store/actions/firestoreActions'
import { ONE_ONE_TOPIC_COLLECTION } from '../../_constants/globals'
import { pickBy } from 'lodash'


export const ModalEditTopic = ({ topic, setTopic }) => {
  
  const dispatch = useDispatch()
  
  const [slug, setSlug] = useState('')
  const [title, setTitle] = useState('')
  const [order, setOrder] = useState(0)
  
  const handleClose = () => {
    setTopic(null)
  }
  
  const handleSubmit = async e => {
    e.preventDefault()
    const object = {
      order: Number(order),
      slug,
      text: {
        fr: title,
      },
    }
    return topic.id
      ? dispatch(updateDocAction(ONE_ONE_TOPIC_COLLECTION, topic?.id, pickBy(object))).then(handleClose)
      : dispatch(addDocAction(ONE_ONE_TOPIC_COLLECTION, object)).then(handleClose)
  }
  
  useEffect(() => {
    if (topic.id) {
      setTitle(topic.text.fr)
      setSlug(topic.slug)
      setOrder(topic.order)
    }
  }, [topic])
  
  return <Dialog open onClose={handleClose}>
    <form onSubmit={handleSubmit}>
      <DialogTitle>{topic ? `Edit topic ${topic.id}` : 'Create topic'}</DialogTitle>
      <DialogContent>
        {!topic.id && <TextField
          label='Id'
          value={slug}
          onChange={event => setSlug(event.target.value)}
          sx={{ display: 'block', m: 2 }}
        />}
        <TextField
          label='Order'
          type='number'
          value={order}
          onChange={event => setOrder(event.target.value)}
          sx={{ display: 'block', m: 2 }}
        />
        <TextField
          label='Title'
          value={title}
          onChange={event => setTitle(event.target.value)}
          sx={{ display: 'block', m: 2 }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color='primary'>Cancel</Button>
        <Button type='submit' color='secondary'>Save</Button>
      </DialogActions>
    </form>
  </Dialog>
}

ModalEditTopic.propTypes = {
  topic: PropTypes.object,
  setTopic: PropTypes.func.isRequired,
}

export default ModalEditTopic
