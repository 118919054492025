import { ERROR, FETCH_COMMITS, FETCH_MASTER_HEAD, MERGE_SUCCESS, SELECT_COMPANY } from '../types'

export default (state = {}, action) => {
  switch (action.type) {
    case ERROR:
      return { ...state, error: action.payload }
    case FETCH_COMMITS:
      return { ...state, commits: action.payload }
    case FETCH_MASTER_HEAD:
      return { ...state, masterHeadSha: action.payload.commit.sha }
    case MERGE_SUCCESS:
      return { ...state, merged: true }
    case SELECT_COMPANY:
      return {
        ...state,
        selectedCompany: action.payload,
      }
    default:
      return state
  }
}
