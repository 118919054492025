/* eslint-disable react/display-name */
import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { Checkbox, FormControl, InputLabel, ListItemText, MenuItem, Select } from '@mui/material'
import MUIDataTable from 'mui-datatables'
import Loading from '../_library/Loading'
import { format } from 'date-fns'
import Avatar from '../_library/Avatar'
import EditUserDialog from './EditUserDialog'
import { getRoles } from '../../_helpers/helpers'
import { Star as StarIcon } from '@mui/icons-material'
import { StyledRating } from '../_library/StyledRating'
import { USER_COLLECTION } from '../../_constants/globals'
import { listenCollection } from '../../store/actions/firestoreActions'
import { compact } from 'lodash'
import CustomSearchRender from './CustomSearchRender'
import { withStyles } from '@mui/styles'


const styles = theme => ({
  root: {
    margin: '20px auto',
    maxWidth: 1200,
  },
  circularProgressContainer: {
    marginTop: 30,
  },
  tableRow: {
    borderBottom: `1px solid ${theme.palette.text.primary}`,
  },
  clickable: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
})

const UserTable = ({ classes, companies, teams, users = [], listenCollection }) => {

  const [selectedUserIndex, selectUserIndex] = useState(null)
  const [itemsPerPage, setItemsPerPage] = useState(50)
  const [search, setSearch] = useState(null)
  const [companyFilters, setCompanyFilters] = useState([])

  useEffect(() => {
    let where
    if (companyFilters.length) where = [['companyRef', 'in', '??']]
    else if (search) where = [['email', '==', search]]
    else where = []
    const listener = listenCollection({
      collection: USER_COLLECTION,
      where,
      orderBy: compact([['_createdAtTime', 'desc'], !where.length && ['email', 'asc']]),
      limit: itemsPerPage,
    })
    return () => {
      listener()
    }
  }, [itemsPerPage, search, companyFilters])

  const selectedUser = useMemo(() => users && selectedUserIndex !== null && users[selectedUserIndex], [users, selectedUserIndex])

  const data = useMemo(() =>
    users?.map(u =>
      [u.id, u.photoUrl, u._createdAtTime, u.email, u.firstname, u.company?.name, u.accessLevel, u.teamRefs, u.gender, u.profileType, u.inviterRef?.id, u.rating],
    ), [users])

  if (!users) return <Loading />
  else return (
    <div className={classes.root}>
      <MUIDataTable
        title='Members List'
        data={data}
        columns={[
          { name: 'ID', options: { filter: false, sort: false, display: 'false' } },
          { name: '', options: { filter: false, sort: false, customBodyRender: url => <Avatar photoUrl={url} /> } },
          { name: 'CreatedAt', options: { filter: false, sort: false, customBodyRender: date => date && format(date.toDate(), 'dd-MM-yyyy') } },
          { name: 'Email', options: { filter: false, sort: false } },
          { name: 'Prénom', options: { filter: false, sort: false } },
          { name: 'Client',
            options: {
              filterType: 'multiselect',
              filterOptions: {
                fullWidth: true,
                logic: () => false,
                display: (filterList, onChange, index, column) => (
                  <FormControl>
                    <InputLabel>Clients</InputLabel>
                    <Select
                      multiple
                      value={filterList[index]}
                      renderValue={selected => companies.filter(c => selected.includes(c.id)).map(c => c.name).join(', ')}
                      onChange={event => {
                        filterList[index] = event.target.value
                        setCompanyFilters(filterList[index])
                        onChange(filterList[index], index, column)
                      }}
                    >
                      {companies?.map(c => (
                        <MenuItem key={c.id} value={c.id}>
                          <Checkbox
                            color='primary'
                            checked={filterList[index].indexOf(c.id) > -1}
                          />
                          <ListItemText primary={c.name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                ),
              },
              customFilterListOptions: {
                render: ids => ids.map(id => companies.find(c => c.id === id).name),
              },
            },
          },
          { name: 'Role',
            options: {
              filter: false,
              sort: false,
              customBodyRender: accessLevel => getRoles().find(r => r.accessLevel === accessLevel)?.title,
            },
          },
          { name: 'Teams',
            options: {
              display: 'false',
              customBodyRender: refs => refs && teams?.filter(t => refs.some(ref => ref.id === t.id)).map(t => t.name).join(', '),
              filterType: 'dropdown',
              filterOptions: {
                fullWidth: true,
                names: teams?.map(t => t.name),
                logic: (location, filters) => {
                  if (filters.length) return !location?.includes(filters[0])
                  else return false
                },
              },
            },
          },
          { name: 'Genre', options: { filter: false, sort: false } },
          { name: 'Profile', options: { filter: false, sort: false } },
          { name: 'Invité par', options: { filter: false, sort: false } },
          { name: 'Rating',
            options: {
              filter: false,
              sort: false,
              customBodyRender: rating => rating &&
                <StyledRating
                  name='customized-color'
                  value={rating || 0}
                  readOnly
                  size='large'
                  icon={<StarIcon fontSize='inherit' />}
                />,
            },
          },
        ]}
        options={{
          filterType: 'textField',
          selectableRows: 'none',
          responsive: 'simple',
          onRowClick: (row, { dataIndex }) => selectUserIndex(dataIndex),
          rowHover: false,
          setRowProps: () => ({
            className: classes.clickable,
          }),
          rowsPerPage: itemsPerPage,
          rowsPerPageOptions: [10, 50, 100, 500],
          onChangeRowsPerPage: setItemsPerPage,
          customSearchRender: (searchText, handleSearch, hideSearch, options) =>
            <CustomSearchRender
              searchText={search}
              onSearch={setSearch}
              onHide={hideSearch}
              options={options}
            />,
        }}
      />
      {selectedUser && <EditUserDialog user={selectedUser} onClose={() => selectUserIndex(null)} />}
    </div>
  )
}

const mapStateToProps = state => ({
  companies: state.data.companies,
  teams: state.firestore.teams,
  talentExtCards: state.data.talentExtCards,
  users: state.firestore.users,
})

UserTable.propTypes = {
  classes: PropTypes.object.isRequired,
  companies: PropTypes.array,
  teams: PropTypes.array,
  users: PropTypes.array,
  listenCollection: PropTypes.func.isRequired,
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, { listenCollection }),
)(UserTable)
