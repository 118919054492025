import { AUTH_ERROR, LISTEN_PROFILE, SIGNOUT_SUCCESS, CAPTCHA_VERIFIED, CONFIRMATION_RESULT } from '../types'
import { USER_COLLECTION } from '../../_constants/globals'
import { signInWithEmailAndPassword, signOut, onAuthStateChanged, RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth'
import { onSnapshot, doc } from 'firebase/firestore'
import { resetLocalStorage } from '../../_helpers/localStorageHelper'


/**
 * Signs in with email and password
 *
 * @param {object} credentials
 */
export const emailSignInAction = credentials => (dispatch, getState, { auth }) =>
  signInWithEmailAndPassword(auth, credentials.email, credentials.password)
    .catch(err => dispatch({
      type: AUTH_ERROR,
      payload: err,
    }))

export const initCaptchaAction = () => (dispatch, getState, { auth }) => {
  window.recaptchaVerifier = new RecaptchaVerifier('phone-sign-in-button', {
    size: 'invisible',
    callback: response => {
      console.log('response', response)
    },
  }, auth)
  return dispatch({ type: CAPTCHA_VERIFIED })
}

/**
 * Signs in with phone
 *
 * @param {string} phoneNumber
 */
export const phoneSignInAction = phoneNumber => (dispatch, getState, { auth }) =>
  signInWithPhoneNumber(auth, phoneNumber, window.recaptchaVerifier)
    .then(confirmationResult => {
      console.log('confirmationResult', confirmationResult)
      return dispatch({
        type: CONFIRMATION_RESULT,
        payload: confirmationResult,
      })
    })
    .catch(err => {
      console.log('err', err)
      return dispatch({
        type: AUTH_ERROR,
        payload: err,
      })
    })

export const signOutAction = () => (dispatch, getState, { auth }) =>
  signOut(auth).then(() => {
    resetLocalStorage()
    window.location.reload()
    return dispatch({ type: SIGNOUT_SUCCESS })
  })

export const listenProfileAction = () => (dispatch, getState, { auth, db }) =>
  onAuthStateChanged(auth, authUser => authUser
    ? onSnapshot(doc(db, USER_COLLECTION, authUser.uid), dbUser => {
      const profile = {
        id: dbUser.id,
        ...dbUser.data(),
        isAnonymous: auth.currentUser.isAnonymous,
        emailVerified: auth.currentUser.emailVerified,
        isLoaded: true,
        isEmpty: false,
        token: auth.currentUser.getIdTokenResult(),
      }
      console.info('profile', profile)
      return dispatch({
        type: LISTEN_PROFILE,
        payload: profile,
      })
    })
    : dispatch({
      type: LISTEN_PROFILE,
      payload: { isLoaded: true, isEmpty: true },
    }),
  )
