import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Typography, IconButton, Box,
} from '@mui/material'
import { CAMPFIRE_TEMPLATE_COLLECTION } from '../../_constants/globals'
import ModalEditCampfire from './ModalEditCampfire'
import ModalDelete from '../_library/ModalDelete'
import { listenCollection } from '../../store/actions/firestoreActions'
import useListener from '../../hooks/useListener'
import Page from '../_library/Page'
import { Delete, Edit } from '@mui/icons-material'


export const CampfirePage = () => {
  
  const templates = useSelector(state => state.firestore.campfireTemplates)
  useListener(() => listenCollection({
    collection: CAMPFIRE_TEMPLATE_COLLECTION,
    orderBy: [['order', 'asc']],
  }), [])
  
  const [edit, setEdit] = useState(null)
  const [deleteDoc, setDeleteDoc] = useState(null)
  
  return (
    <Page withTopBarAndMenu>
      <Typography variant='h1'>Campfire types</Typography>
      <Button variant='contained' color='primary' onClick={() => setEdit({})} sx={{ m: 1 }}>Add a type</Button>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold' }} />
              <TableCell sx={{ fontWeight: 'bold' }}>ID</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Title</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Order</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {templates?.map(template => (
              <TableRow key={template.id}>
                <TableCell scope='row'><Box component='img' src={template.cardUrl} alt={template.slug} sx={{ maxWidth: 100 }} /></TableCell>
                <TableCell scope='row'>{template.slug}</TableCell>
                <TableCell scope='row'>{template.title?.fr}</TableCell>
                <TableCell scope='row'>{template.order}</TableCell>
                <TableCell>
                  <IconButton onClick={() => setEdit(template)} sx={{ color: 'primary.main' }}>
                    <Edit />
                  </IconButton>
                  <IconButton onClick={() => setDeleteDoc(template)} sx={{ color: 'secondary.main' }}>
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {edit && <ModalEditCampfire template={edit} setTemplate={setEdit} />}
      {deleteDoc && <ModalDelete collection={CAMPFIRE_TEMPLATE_COLLECTION} id={deleteDoc.id} callback={() => setDeleteDoc(null)} />}
    </Page>
  )
}

export default CampfirePage
