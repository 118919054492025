import React from 'react'
import PropTypes from 'prop-types'
import { Tooltip, useMediaQuery, useTheme } from '@mui/material'
import avatarTeamMember from '../../assets/avatar_team_member.svg'
import clsx from 'clsx'
import { compose } from 'recompose'
import { withStyles } from '@mui/styles'


const styles = () => ({
  root: {
    position: 'absolute',
    zIndex: 1,
    cursor: 'pointer',
  },
  imgContainer: {
    overflow: 'hidden',
    border: 'solid white',
    backgroundSize: 'cover',
    boxShadow: '2px 2px 5px rgba(50, 50, 50, .4)',
    margin: 'auto',
  },
  firstname: {
    backgroundColor: 'white',
    color: 'black',
    fontWeight: 700,
    textAlign: 'center',
    borderRadius: 5,
    margin: '-3px auto',
    lineHeight: '1.5em',
    boxShadow: '2px 2px 5px rgba(50, 50, 50, .4)',
    width: 'fit-content',
    padding: '0 5px',
  },
})

export const AvatarGraph = ({ photoUrl, classes, style, onClick, firstname, className, size = 60 }) => {
  
  const theme = useTheme()
  const isDownSm = useMediaQuery(theme.breakpoints.down('sm'))

  const diameter = isDownSm ? size * 2 / 3 : size
  const displayFirstname = size >= 30

  return <Tooltip title={firstname} disableHoverListener={displayFirstname}>
    <div className={clsx(classes.root, className)} style={style}>
      {photoUrl
        ? <div
          className={classes.imgContainer}
          onClick={onClick}
          style={{
            backgroundImage: `url('${photoUrl}')`,
            height: diameter,
            width: diameter,
            borderRadius: diameter / 2,
            borderWidth: diameter / 15,
          }}
        />
        : <div
          className={classes.imgContainer}
          onClick={onClick}
          style={{
            backgroundImage: `url('${avatarTeamMember}')`,
            border: 'none',
            height: diameter,
            width: diameter,
            borderRadius: diameter / 2,
            borderWidth: diameter / 15,
          }}
        />
      }
      {firstname && displayFirstname && <div className={classes.firstname} style={{ fontSize: `${size / 5}px` }}>{firstname}</div>}
    </div>
  </Tooltip>
}

AvatarGraph.propTypes = {
  classes: PropTypes.object.isRequired,
  photoUrl: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func,
  firstname: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.number,
}

export default compose(
  withStyles(styles),
)(AvatarGraph)
